import { Box } from "@mui/material";
import { BackHeader } from "Components/BackHeader/BackHeader";
import Page from "Components/Page/Page";
import { PostCreator } from "Components/PostCreator/PostCreator";
import { useContext } from "react";
import { createPost } from "Redux/communitySlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./NewPost.module.scss";

const NewPost = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <Page className={styles.container}>
      <Box className={styles.header}>
        <BackHeader title={t("community.newPost")} />
      </Box>
      <PostCreator
        sendButtonTitle={t("community.sendMessage")}
        onSubmitHandler={createPost}
      />
    </Page>
  );
};

export default NewPost;
