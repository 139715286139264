import {
  getAccessToken,
  getAuthUsername,
  getEmulateId,
  getGoogleAccessToken,
  getGoogleRefreshToken,
  getIdToken,
  getRefreshToken,
} from "Services/Auth/auth.service";
import { clientId, userPoolId } from "Services/userPool";

import { MainAppDomain } from "../Constants";

const getParams = () => {
  const refreshToken = getRefreshToken();
  const username = getAuthUsername();
  const accessToken = getAccessToken();
  const idToken = getIdToken();
  const emulateInfluencerId = getEmulateId();
  const googleRefreshToken = getGoogleRefreshToken();
  const googleAccessToken = getGoogleAccessToken();

  return {
    refreshToken,
    username,
    accessToken,
    idToken,
    emulateInfluencerId,
    googleRefreshToken,
    googleAccessToken,
  };
};

export const goToMainAppProfile = ({ pathname, isReturn = false }) => {
  const {
    refreshToken,
    username,
    accessToken,
    idToken,
    emulateInfluencerId,
    googleRefreshToken,
    googleAccessToken,
  } = getParams();
  let url = pathname ? `${MainAppDomain}/${pathname}` : MainAppDomain;
  if (googleAccessToken) {
    url += `?googleRefreshToken=${googleRefreshToken}&googleAccessToken=${googleAccessToken}`;
  } else {
    url += `?&username=${username}&refreshToken=${refreshToken}&accessToken=${accessToken}&idToken=${idToken}&clientId=${clientId}&userPoolId${userPoolId}`;
  }
  if (emulateInfluencerId) {
    url = `${url}&emulateInfluencerId=${emulateInfluencerId}`;
  }

  if (isReturn) {
    return url;
  } else {
    window.open(url, "_self");
  }
};
