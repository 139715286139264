import { Box, Skeleton } from "@mui/material";
import { REWARDS_ACTION_TYPE } from "Constants/index";
import { useContext } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { HiddenContent } from "./components/HiddenContent/HiddenContent";
import { RewardCardChat } from "./components/RewardCardChat/RewardCardChat";
import styles from "./Rewards.module.scss";

export const Rewards = ({
  rewardType,
  media,
  isLoading,
  awaitingOrdersCount,
  duration,
}) => {
  const { t } = useContext(LocalizationContext);

  const statusTitle = awaitingOrdersCount
    ? t("limitedEdition.fansAwaiting", { count: awaitingOrdersCount })
    : null;

  if (
    !isLoading &&
    (!rewardType ||
      (rewardType === REWARDS_ACTION_TYPE.HIDDEN_CONTENT && !media.length))
  ) {
    return <></>;
  }

  if (isLoading) {
    return <Skeleton variant="rectangular" className={styles.skeleton} />;
  }

  return (
    <Box className={styles.wrapper}>
      {rewardType === REWARDS_ACTION_TYPE.HIDDEN_CONTENT && media.length ? (
        <HiddenContent media={media} />
      ) : null}
      {rewardType === REWARDS_ACTION_TYPE.PREMIUM_CHAT ? (
        <RewardCardChat title={t("limitedEdition.premiumChat")} />
      ) : null}
      {rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK ? (
        <RewardCardChat
          title={t("limitedEdition.followBack")}
          statusTitle={statusTitle}
        />
      ) : null}
      {rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL ? (
        <RewardCardChat
          title={t("limitedEdition.privateVideoCall", { value: duration })}
          statusTitle={statusTitle}
        />
      ) : null}
    </Box>
  );
};
