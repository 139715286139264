import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as IconMail } from "assets/svg/icon-mail.svg";
import { ReactComponent as IconPickier } from "assets/svg/icon-picker.svg";
import { FETCH_POSTS_LIMIT, PATHS, USER_ME_STATUS } from "Constants";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "Redux/appSlice";
import { fetchPosts } from "Redux/communitySlice";
import {
  getPinnedPostCommunity,
  getPostsCommunity,
  getPostsCommunityTotal,
  getPostsLoader,
} from "Redux/selectors/communitySelectors";
import { getUsersMeCommunityGuidelinesAccepted } from "Redux/selectors/userSelector";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { getPosts } from "Services/Api/communityApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "../Community.module.scss";
import CommunityPost from "./CommunityPost/CommunityPost";
import { SkeletonBlock } from "./Skeleton";

export const Posts = ({ status }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [refetchPost, setRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const [scheduled, setScheduled] = useState({ posts: [] });
  const [scheduledLoader, setScheduledLoader] = useState(true);

  const loading = useSelector(getPostsLoader);
  const posts = useSelector(getPostsCommunity);
  const pinnedPost = useSelector(getPinnedPostCommunity);
  const total = useSelector(getPostsCommunityTotal);
  const communityGuidelinesAccepted = useSelector(
    getUsersMeCommunityGuidelinesAccepted
  );
  const fetchMorePosts = () => {
    setRefetch(true);
    dispatch(fetchPosts({ limit: FETCH_POSTS_LIMIT, page: page + 1 })).then(
      () => {
        setPage(page + 1);
        setRefetch(false);
      }
    );
  };

  const renderPosts = useMemo(() => {
    if (status === USER_ME_STATUS.NEW || !communityGuidelinesAccepted) {
      return null;
    }
    return {
      pinnedPost: pinnedPost ? (
        <CommunityPost key={pinnedPost.id} {...pinnedPost} postIndex="-1" />
      ) : null,
      posts: posts.map((post, index) =>
        pinnedPost?.id !== post?.id ? (
          <CommunityPost key={post.id} {...post} postIndex={index} />
        ) : null
      ),
    };
  }, [status, communityGuidelinesAccepted, pinnedPost, posts]);

  useEffect(() => {
    getPosts({ limit: FETCH_POSTS_LIMIT, page: 1, type: "scheduled" })
      .then((data) => {
        setScheduled(data);
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              title: t("errors.error"),
              subtitle: errorResponseMessages(err, t),
            })
          );
        }
      })
      .finally(() => setScheduledLoader(false));
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {loading && !refetchPost ? (
        <SkeletonBlock />
      ) : (
        <>
          <Box>
            <Box
              className={`${styles.currentTierHeader} ${styles.currentTierHeaderColumnMobile}`}
            >
              <Typography variant="subtitle" className={styles.sectionTitle}>
                {t("community.postsTitle")}
                <Typography
                  variant="body18"
                  className={styles.sectionTitleValue}
                  component="span"
                >
                  ({total})
                </Typography>
              </Typography>
              {total > 0 &&
              status !== USER_ME_STATUS.NEW &&
              communityGuidelinesAccepted ? (
                <Box>
                  <Button
                    variant="contained"
                    className={styles.btnNewPost}
                    onClick={() => navigate(PATHS.NEW_POST)}
                  >
                    <Box component="span">{t("community.newPost")}</Box>
                    <IconMail />
                  </Button>
                  <LoadingButton
                    variant="outlined"
                    className={`${styles.btnOutlined} ${styles.btnScheduledPost}`}
                    onClick={() => navigate(PATHS.SCHEDULED_POSTS)}
                    loading={scheduledLoader}
                    disabled={!scheduled?.posts?.length}
                  >
                    <Box component="span">{t("community.viewScheduled")}</Box>
                    <IconPickier />
                  </LoadingButton>
                </Box>
              ) : null}
            </Box>
            {renderPosts?.pinnedPost}
            {renderPosts?.posts}
            {posts?.length < total &&
            status !== USER_ME_STATUS.NEW &&
            communityGuidelinesAccepted ? (
              <LoadingButton
                variant="outlined"
                className={styles.btnLoadMore}
                onClick={fetchMorePosts}
                loading={refetchPost}
                size="small"
              >
                {!refetchPost ? t("community.loadMore") : ""}
              </LoadingButton>
            ) : null}
          </Box>
        </>
      )}
    </Box>
  );
};
