import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as ArrowIcon } from "assets/svg/arrow-down.svg";
import { ReactComponent as ErrorIcon } from "assets/svg/error-icon-poll.svg";
import { ReactComponent as AddIcon } from "assets/svg/icon-add-perk-mobile.svg";
import { ReactComponent as DragHandleIcon } from "assets/svg/icon-burger-menu.svg";
import { ReactComponent as CloseIcon } from "assets/svg/icon-close.svg";
import classNames from "classnames/bind";
import React, { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Controller, useFieldArray } from "react-hook-form";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./PollSection.module.scss";

// React.StrictMode breaks react-beautiful-dnd. Comment to work on dev env.

export const PollSection = ({
  isActive,
  control,
  errors,
  hasVotes,
  trigger,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1260));
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "pollOptions",
  });
  const { t } = useContext(LocalizationContext);

  if (!isActive) {
    return null;
  }

  const onDragEnd = (result) => {
    if (!result.destination) return;
    move(result.source.index, result.destination.index);
  };
  const cx = classNames.bind(styles);
  const getDurationSelector = () => (
    <FormControl className={`${styles.wrapper} ${styles.duration}`}>
      <Controller
        name="duration"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            defaultValue={null}
            displayEmpty
            className={styles.select}
            MenuProps={{
              classes: {
                paper: styles.selectMenu,
              },
            }}
            IconComponent={ArrowIcon}
          >
            <MenuItem value={null} className={styles.durationMenuItem}>
              {t("community.pollDurationNoLimit")}
            </MenuItem>
            <MenuItem value={1} className={styles.durationMenuItem}>
              {t("community.pollDuration1Day")}
            </MenuItem>
            <MenuItem value={3} className={styles.durationMenuItem}>
              {t("community.pollDuration3Days")}
            </MenuItem>
            <MenuItem value={7} className={styles.durationMenuItem}>
              {t("community.pollDuration7Days")}
            </MenuItem>
            <MenuItem value={30} className={styles.durationMenuItem}>
              {t("community.pollDuration30Day")}
            </MenuItem>
          </Select>
        )}
      />
      <Typography variant="body3_medium_14_display" className={styles.label}>
        {t("community.pollDuration")}
      </Typography>
      <Typography variant="error">{errors?.duration?.message}</Typography>
    </FormControl>
  );

  return (
    <Box className={styles.pollWrapper}>
      {hasVotes ? (
        <Box className={styles.disabledPollNoteWrapper}>
          <ErrorIcon
            width={18}
            height={18}
            className={styles.disabledPollIcon}
          />
          <Typography variant="body18">
            {t("community.pollDisableEdit")}
          </Typography>
        </Box>
      ) : null}
      <Box className={`${hasVotes ? styles.disablePoll : ""}`}>
        <Typography variant="body4" className={styles.title}>
          {t("community.pollOptions")}
        </Typography>
        <Box>
          <Box className={styles.topInputsWrapper}>
            <FormControl
              className={cx("wrapper", "question", {
                textfieldError: !!errors?.pollQuestion,
              })}
            >
              <Typography
                variant="body3_medium_14_display"
                className={styles.label}
              >
                {t("community.pollQuestion")}
              </Typography>
              <Controller
                name="pollQuestion"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      placeholder={t("community.pollQuestionPlaceholder")}
                      variant="outlined"
                    />
                  );
                }}
              />
            </FormControl>
            {!isMobile ? getDurationSelector() : null}
          </Box>
          <Typography variant="error" className={styles.pollError}>
            {errors?.pollQuestion?.message}
          </Typography>
        </Box>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="pollOptions">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.pollOptionsWrapper}
              >
                {fields.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={`pollOption-${item.id}`}
                    index={index}
                  >
                    {(provided) => (
                      <Box ref={provided.innerRef} {...provided.draggableProps}>
                        <FormControl
                          className={cx("wrapper", {
                            textfieldError:
                              !!errors?.pollOptions?.[index]?.option,
                          })}
                        >
                          <TextField
                            className={styles.optionInput}
                            {...provided.dragHandleProps}
                            {...control.register(`pollOptions.${index}.option`)}
                            placeholder={t("community.pollOptionIndex", {
                              index: index + 1,
                            })}
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <DragHandleIcon className={styles.dragIcon} />
                                </InputAdornment>
                              ),
                              onInput: () => {
                                trigger("pollOptions");
                              },
                            }}
                          />
                          {(item?.option.length > 0 || index > 1) && (
                            <Button
                              className={styles.closeBtn}
                              onClick={() => remove(index)}
                            >
                              <CloseIcon />
                            </Button>
                          )}
                        </FormControl>
                        {errors?.pollOptions ? (
                          <Typography
                            variant="error"
                            className={styles.pollOptionsError}
                          >
                            {errors.pollOptions[index]?.option?.message}
                          </Typography>
                        ) : null}
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {errors?.pollOptions?.message ? (
                  <Typography
                    variant="error"
                    className={styles.pollOptionsError}
                  >
                    {errors.pollOptions?.message}
                  </Typography>
                ) : null}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        {isMobile ? (
          <Button
            className={styles.addBtnMobile}
            onClick={() => append({ option: "" })}
          >
            <AddIcon />
            <Typography variant="body3" className={styles.addBtnMobileContent}>
              {t("community.pollOptionAddBtnMobile")}
            </Typography>
          </Button>
        ) : (
          <Button
            className={styles.addBtn}
            onClick={() => append({ option: "" })}
          >
            {t("community.pollOptionAddBtn")}
          </Button>
        )}
        {isMobile ? getDurationSelector() : null}
      </Box>
    </Box>
  );
};
