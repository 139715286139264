// Auth helper for storing and getting token data from local storage
import { AUTH, EMULATE } from "Constants";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";

import { setTokens } from "../../Redux/appSlice";
import up from "../userPool";
import { getAdminUserPool } from "../userPoolAdmin";

export const logout = () => {
  up.getCurrentUser()?.signOut();
  if (getEmulateId()) {
    const UserPoolAdmin = getAdminUserPool();
    UserPoolAdmin.getCurrentUser()?.signOut();
  }
  clearTokens();
  localStorage.removeItem(EMULATE.LOCAL_STORAGE_ID);
};

export const isAuthenticated = () => {
  const token = getAccessToken() || getGoogleAccessToken();
  return !!token;
};

export const setAccessToken = (token) => {
  const res = localStorage.setItem(AUTH.ACCESS_TOKEN, token);
  return res;
};

export const setIdToken = (token) => {
  return localStorage.setItem(AUTH.ID_TOKEN, token);
};

export const getIdToken = () => {
  return localStorage.getItem(AUTH.ID_TOKEN);
};

export const setAuthUsername = (token) => {
  return localStorage.setItem(AUTH.AUTH_USERNAME, token);
};

export const getAuthUsername = () => {
  return localStorage.getItem(AUTH.AUTH_USERNAME);
};

export const getAccessToken = () => {
  return localStorage.getItem(AUTH.ACCESS_TOKEN);
};

export const setRefreshToken = (token) => {
  return localStorage.setItem(AUTH.REFRESH_TOKEN, token);
};

export const getRefreshToken = () => {
  return localStorage.getItem(AUTH.REFRESH_TOKEN);
};

export const setUUID = () => {
  return localStorage.setItem(AUTH.UUID, uuidv4());
};

export const getGoogleAccessToken = () => {
  return localStorage.getItem(AUTH.GOOGLE_ACCESS_TOKEN);
};

export const setGoogleAccessToken = (token) => {
  return localStorage.setItem(AUTH.GOOGLE_ACCESS_TOKEN, token);
};

export const getGoogleRefreshToken = () => {
  return localStorage.getItem(AUTH.GOOGLE_REFRESH_TOKEN);
};

export const setGoogleRefreshToken = (token) => {
  return localStorage.setItem(AUTH.GOOGLE_REFRESH_TOKEN, token);
};

export const setInviteCode = (code) => {
  return localStorage.setItem(AUTH.INVITE_CODE, code);
};

export const getInviteCode = () => {
  return localStorage.getItem(AUTH.INVITE_CODE);
};

export const removeInviteCode = () => {
  return localStorage.removeItem(AUTH.INVITE_CODE);
};

export const setZendeskToken = (token) => {
  return localStorage.setItem(AUTH.ZENDESK_TOKEN, token);
};

export const getZendeskToken = () => {
  return localStorage.getItem(AUTH.ZENDESK_TOKEN);
};

export const setEmulateId = (emulateId) => {
  return localStorage.setItem(EMULATE.LOCAL_STORAGE_ID, emulateId);
};

export const getEmulateId = () => {
  return localStorage.getItem(EMULATE.LOCAL_STORAGE_ID);
};

export const clearTokens = () => {
  localStorage.removeItem(AUTH.ACCESS_TOKEN);
  localStorage.removeItem(AUTH.REFRESH_TOKEN);
  localStorage.removeItem(AUTH.ID_TOKEN);
  localStorage.removeItem(AUTH.UUID);
  localStorage.removeItem(AUTH.INVITE_CODE);
  localStorage.removeItem(AUTH.ZENDESK_TOKEN);
  localStorage.removeItem(AUTH.AUTH_USERNAME);
  localStorage.removeItem(AUTH.GOOGLE_REFRESH_TOKEN);
  localStorage.removeItem(AUTH.GOOGLE_ACCESS_TOKEN);
};

export const getTokens = () => {
  const accessToken = getAccessToken();
  const idToken = getIdToken();

  return (
    accessToken && {
      accessToken: idToken,
      expireAt: idToken && jwt_decode(accessToken)?.exp,
      refreshToken: getRefreshToken(),
      idToken: getIdToken(),
    }
  );
};
export const setAuth = ({ data, isCrossDomain = false, dispatch }) => {
  const accessToken = isCrossDomain
    ? data.accessToken
    : data.getAccessToken().getJwtToken();
  const idToken = isCrossDomain
    ? data.idToken
    : data.getIdToken().getJwtToken();
  const refreshToken = isCrossDomain
    ? data.refreshToken
    : data.getRefreshToken().getToken();
  const username = isCrossDomain
    ? data.username
    : data.accessToken.payload.username;
  accessToken && setAccessToken(accessToken);
  idToken && setIdToken(idToken);
  refreshToken && setRefreshToken(refreshToken);
  username && setAuthUsername(username);
  setUUID();
  if (dispatch) {
    dispatch(setTokens({ accessToken, idToken }));
  }
};
