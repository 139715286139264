import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "Components/Header/";
import MobileMenu from "Components/MobileMenu/index";
import Sidebar from "Components/Sidebar/";
import { COOKIE, COOKIE_ACCEPTED, SNACKBAR_TYPE } from "Constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { setLoader, setSnackbar } from "Redux/appSlice";
import { getAuthProcess } from "Redux/selectors/appSelector";
import { isAuthenticated } from "Services/Auth/auth.service";
import {
  PublicRoute,
  RedirectTo,
  StripeOnboardingRefreshUrl,
  StripeOnboardingReturnUrl,
} from "Utils/RouterGuards";
import ClubMembers from "Views/ClubMembers/ClubMembers";
import Community from "Views/Community/Community";
import Followers from "Views/Followers/Followers";
import ForgotPassword from "Views/ForgotPassword/";
import Insights from "Views/Insights";
import MainPage from "Views/Join/MainPage";
import Onfido from "Views/KYC/Onfido";
import SignIn from "Views/Login/";
import EditPost from "Views/NewPost/EditPost";
import NewPost from "Views/NewPost/NewPost";
import NoMatch from "Views/Nomatch";
import Post from "Views/Post/Post";
import Profile from "Views/Profile/Profile";
import EmailNotificationsSettings from "Views/Settings/EmailNotifications";
import Help from "Views/Settings/Help/Help";
import Settings from "Views/Settings/Settings";
import SetupPassword from "Views/SetupPassword/";

import ErrorModal from "./Components/Modal/components/ErrorModal/ErrorModal";
import { PATHS } from "./Constants";
import { fetchUser } from "./Redux/mainAppUserSlice";
import { getGlobalUnreadCountMessages } from "./Redux/messengerSlice";
import { fetchUserMe } from "./Redux/usersSlice";
import { AccountSuspended } from "./Views/AccountSuspended/AccountSuspended";
import { CardConstructor } from "./Views/CardConstructor/CardConstructor";
import { CardDetails } from "./Views/CardDetails/CardDetails";
import { Earnings } from "./Views/Earnings/Earnings";
import EditCommunity from "./Views/EditCommunity/EditCommunity";
import { EditSeasonRewards } from "./Views/EditSeasonRewards/EditSeasonRewards";
import { EmailVerification } from "./Views/EmailVerification/EmailVerification";
import Kyc from "./Views/KYC/KYC";
import { LimitedEdition } from "./Views/LimitedEdition/LimitedEdition";
import MeetingRoom from "./Views/MeetingRoom/MeetingRoom";
import { Messages } from "./Views/Messages/Messages";
import { RewardOrders } from "./Views/RewardOrders/RewardOrders";
import { ScheduledPosts } from "./Views/ScheduledPosts/ScheduledPosts";
import { Seasons } from "./Views/Seasons/Seasons";
import { SignUp } from "./Views/SIgnUp/SignUp";
import { Transactions } from "./Views/Transactions/Transactions";
import { VideoCalls } from "./Views/VideoCalls/VideoCalls";

function Router() {
  const dispatch = useDispatch();
  const isLoggedIn = isAuthenticated();
  const theme = useTheme();
  const isAuthProcess = useSelector(getAuthProcess);
  const mobileBP = 769;
  const mobileWidth = useMediaQuery(theme.breakpoints.down(mobileBP));
  let location = useLocation();

  const isHideSidebar =
    ["/404"].includes(location.pathname) ||
    location.pathname.includes("/post") ||
    location.pathname.includes(PATHS.EMAIL_VERIFICATION) ||
    location.pathname.includes(PATHS.COMPLETE_PROFILE) ||
    location.pathname.includes(PATHS.SIGN_UP);

  useEffect(() => {
    const cookie = localStorage.getItem(COOKIE);

    if (cookie !== COOKIE_ACCEPTED) {
      dispatch(
        setSnackbar({
          open: true,
          left: "40px",
          right: "40px",
          width: "auto",
          unclosable: true,
          autoHideDuration: null,
          type: SNACKBAR_TYPE.COOKIE,
        })
      );
    }
    if (!isAuthProcess) {
      dispatch(fetchUserMe());
      dispatch(fetchUser());
      dispatch(getGlobalUnreadCountMessages());
    }
  }, [isAuthProcess]);

  if (isAuthProcess) {
    return <></>;
  }

  return (
    <Box className={isLoggedIn && !isHideSidebar ? "with-sidebar" : ""}>
      {isLoggedIn && !isHideSidebar && <Sidebar />}
      {mobileWidth && isLoggedIn && <Header />}
      {mobileWidth && isLoggedIn && <MobileMenu />}
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path={PATHS.JOIN_CODE} element={<MainPage />} />
          <Route path={PATHS.JOIN} element={<MainPage />} />
          <Route path={PATHS.SIGNIN} element={<SignIn />} />
          <Route path={PATHS.GOOGLE_SIGNUP} element={<SignIn />} />
          <Route path={PATHS.SETUP_PASSWORD} element={<SetupPassword />} />
          <Route path={PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={PATHS.SIGN_UP_CODE} element={<SignUp />} />
          <Route path={PATHS.SIGN_UP} element={<SignUp />} />
          <Route path={PATHS.SIGN_UP_CODE} element={<SignUp />} />
          <Route path={PATHS.SIGN_UP} element={<SignUp />} />
        </Route>
        <Route element={<RedirectTo />}>
          <Route
            path={PATHS.EMAIL_VERIFICATION}
            element={<EmailVerification />}
          />
          <Route path={PATHS.MEETING} element={<MeetingRoom />} />
          <Route path={PATHS.MEETING_ID} element={<MeetingRoom />} />
          <Route path={PATHS.HOME} element={<Community />} />
          <Route path={PATHS.REWARD_ORDERS} element={<RewardOrders />} />
          <Route path={PATHS.VIDEO_CALLS} element={<VideoCalls />} />
          <Route path={PATHS.LIMITED_EDITION_CARD} element={<CardDetails />} />
          <Route path={PATHS.CARD_CONSTRUCTOR} element={<CardConstructor />} />
          <Route
            path={PATHS.CARD_CONSTRUCTOR_EDIT}
            element={<CardConstructor />}
          />

          <Route path={PATHS.COMMUNITY} element={<Community />} />
          <Route path={PATHS.POST_ID} element={<Post />} />
          <Route path={PATHS.SEASONS} element={<Seasons />} />
          <Route
            path={PATHS.SEASONS_REWARDS_EDIT}
            element={<EditSeasonRewards />}
          />
          <Route path={PATHS.NEW_POST} element={<NewPost />} />
          <Route path={PATHS.EDIT_POST} element={<EditPost />} />
          <Route path={PATHS.SCHEDULED_POSTS} element={<ScheduledPosts />} />
          <Route path={PATHS.PROFILE} element={<Profile />} />
          <Route path={PATHS.COMPLETE_PROFILE} element={<Kyc />} />

          <Route path={PATHS.CHAT_DIRECT} element={<Messages />} />

          <Route path={PATHS.EARNINGS} element={<Earnings />} />
          <Route path={PATHS.TRANSACTIONS} element={<Transactions />} />
          <Route path={PATHS.LIMITED_EDITION} element={<LimitedEdition />} />
          <Route path={PATHS.FOLLOWERS} element={<Followers />} />
          <Route path={PATHS.CHAT} element={<Messages />} />
          <Route path={PATHS.INSIGHTS} element={<Insights />} />
          <Route path={PATHS.CLUB_MEMBERS} element={<ClubMembers />} />
          <Route path={PATHS.EDIT_COMMUNITY} element={<EditCommunity />} />

          <Route path={PATHS.SETTINGS} element={<Settings />}>
            {!mobileWidth && (
              <Route index element={<EmailNotificationsSettings />} />
            )}
            <Route
              index
              path={PATHS.LAST_SEGMENT_EMAIL_NOTIFICATIONS}
              element={<EmailNotificationsSettings />}
            />
            <Route path={PATHS.HELP} element={<Help />} />
          </Route>
        </Route>
        <Route
          path={PATHS.PAYMENTS_INFLUENCER_ID_RETURN}
          element={<StripeOnboardingReturnUrl />}
        />
        <Route
          path={PATHS.PAYMENTS_INFLUENCER_ID_REFRESH}
          element={<StripeOnboardingRefreshUrl />}
        />
        <Route path={PATHS.ACCOUNT_SUSPENDED} element={<AccountSuspended />} />
        <Route
          path="*"
          element={
            <Navigate to={PATHS["404"]} state={{ from: location }} replace />
          }
        />
        <Route path="404" element={<NoMatch />} />
      </Routes>
      <Onfido />
      <ErrorModal />
    </Box>
  );
}

export default Router;
