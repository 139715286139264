import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, FormControl, IconButton, Input, Typography } from "@mui/material";
import { ReactComponent as IconBack } from "assets/svg/arrow-left.svg";
import { isValid } from "date-fns";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "Redux/appSlice";
import { updateUserProfile } from "Redux/mainAppUserSlice";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";
import * as Yup from "yup";

import styles from "./KYC.module.scss";
import SeparateDateInput from "./SeparateDateInput/index";

function parseDateString(value) {
  const date = new Date(value);
  if (!isValid(date) || date > new Date()) {
    return false;
  }
  return true;
}

const transformDate = (date) => {
  if (!isValid(new Date(date)) || !date) return null;

  const newBirthday = date.split("-");
  return `${newBirthday[1]}/${newBirthday[2]}/${newBirthday[0]}`;
};

const Step3 = ({ handleNext, handleBack }) => {
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const user = useSelector((state) => state.mainAppUser.entities);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(t("errors.required"))
      .validateLatinLetter(t("errors.onlyEnglishLetter")),
    lastName: Yup.string()
      .trim()
      .required(t("errors.required"))
      .validateLatinLetter(t("errors.onlyEnglishLetter")),
    dateOfBirth: Yup.string()
      .test("is-valid-date", t("errors.birthdayNotValid"), (value) => {
        const isFull = value.split("/").every((item) => item.length > 0);
        return isFull && parseDateString(value);
      })
      .required(t("errors.required")),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      dateOfBirth: transformDate(user?.dateOfBirth) || "",
    },
  });
  const navigate = useNavigate();
  const onBack = () => {
    if (user?.emailVerified) {
      return navigate(-1, { replace: true });
    }
    handleBack();
  };
  const handleFormSubmit = (data) => {
    setLoading(true);
    const payload = { ...data };

    const newBirthday = payload.dateOfBirth.split("/");
    payload.dateOfBirth = `${newBirthday[2]}-${newBirthday[0]}-${newBirthday[1]}`;
    dispatch(updateUserProfile(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
        handleNext();
      })
      .catch((e) => {
        setLoading(false);
        //TODO:: add translation for {error: ‘ProhibitedJurisdiction’,
        // message: ‘Prohibited jurisdiction’}
        dispatch(
          setError({
            open: true,
            title: t("errors.error"),
            subtitle: errorResponseMessages(e, t),
          })
        );
      });
  };

  return (
    <>
      {!isMobile && (
        <IconButton className={styles.back} onClick={onBack}>
          <IconBack />
        </IconButton>
      )}
      <Box className={[styles.containerStep1, styles.containerCenter]}>
        <>
          <Typography variant="h3" component="h1" className={styles.title}>
            {t("kyc.personalInformation")}
          </Typography>
          <Box className={styles.form}>
            <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>{t("default.firstName")}</label>
                <Input
                  placeholder={t("default.firstName")}
                  {...register("firstName")}
                  error={errors.firstName ? true : false}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">
                  {errors.firstName?.message}
                </Typography>
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>{t("default.lastName")}</label>
                <Input
                  placeholder={t("default.lastName")}
                  {...register("lastName")}
                  error={errors.lastName ? true : false}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">
                  {errors.lastName?.message}
                </Typography>
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("default.dateOfBirth")}
                </label>
                <Box mt={2}>
                  <SeparateDateInput
                    defaultValue={transformDate(user?.dateOfBirth)}
                    onChange={(value) => setValue("dateOfBirth", value)}
                    validation={{ ...register("dateOfBirth") }}
                  />
                </Box>
                {!!errors.dateOfBirth ? (
                  <Typography variant="error" sx={{ mt: 1 }}>
                    {errors.dateOfBirth?.message}
                  </Typography>
                ) : null}
              </FormControl>
              <FormControl>
                <LoadingButton
                  type="submit"
                  variant="outlined"
                  className={styles.btnContinue}
                  loading={loading}
                >
                  {t("kyc.continueButton")}
                </LoadingButton>
              </FormControl>
            </form>
          </Box>
        </>
      </Box>
    </>
  );
};

export default Step3;
