import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { ReactComponent as IconKyc } from "assets/svg/kyc.svg";
import { MODAL_TYPE } from "Constants";
import React, { Suspense, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "Redux/appSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { ScheduleModal } from "./components/ScheduleModal/ScheduleModal";
import styles from "./Modal.module.scss";

const MODALS = {
  [MODAL_TYPE.DELETE_POST]: React.lazy(() =>
    import("Components/Modal/DeletePostModal")
  ),
  [MODAL_TYPE.COMMUNITY_GUIDELINES]: React.lazy(() =>
    import("Components/CommunityGuidelines/CommunityGuidelines")
  ),
  [MODAL_TYPE.REPORT_MODAL]: React.lazy(() =>
    import("./components/ReportModal/index")
  ),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 286,
  bgcolor: "#111322",
  border: "2px solid #21264E",
  boxSizing: "border-box",
  borderRadius: "10px",
  padding: "16px",
};

const ModalContainer = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.app.modal);

  const { t } = useContext(LocalizationContext);
  const handleClose = () => {
    dispatch(setModal({ open: false, payload: null }));
  };

  const renderModal = () => {
    switch (modal.type) {
      case MODAL_TYPE.DELETE_POST: {
        return <MODALS.DELETE_POST {...modal.payload} />;
      }
      case MODAL_TYPE.COMMUNITY_GUIDELINES: {
        return <MODALS.COMMUNITY_GUIDELINES {...modal.payload} />;
      }
      case MODAL_TYPE.REPORT_MODAL: {
        return <MODALS.REPORT_MODAL {...modal.payload} />;
      }
      case MODAL_TYPE.VERIFY: {
        const {
          payload: { children, title, onClick },
        } = modal || {};
        return (
          <Box sx={style}>
            <IconKyc />
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={styles.modalTitle}
            >
              {title}
            </Typography>
            <Box>{children}</Box>
            <Button
              variant="red"
              className={styles.buttonOkay}
              onClick={() => {
                handleClose();
                onClick();
              }}
            >
              {t("kyc.okay")}
            </Button>
          </Box>
        );
      }
      case MODAL_TYPE.SCHEDULE_MODAL: {
        return <ScheduleModal {...modal.payload} />;
      }
      case MODAL_TYPE.MODAL: {
        return (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "16px",
              right: "16px",
              transform: "translateY(-50%)",
              margin: "0 auto",
              maxWidth: 600,
              width: "calc(100% - 32px)",
              bgcolor: "#111322",
              border: "2px solid #21264E",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "16px",
            }}
          >
            {modal?.payload?.children}
          </Box>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <Modal
      open={modal.open}
      onClose={() => {
        dispatch(setModal({ open: false, payload: null }));
      }}
      classes={modal?.overrideClasses}
    >
      <Suspense
        fallback={
          <Box>
            <CircularProgress />
          </Box>
        }
      >
        {renderModal()}
      </Suspense>
    </Modal>
  );
};

export default React.memo(ModalContainer);
