import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { ReactComponent as IconRight } from "assets/svg/icon-right.svg";
import ZendeskWidget, { showWidgetButton } from "Components/ZendeskWidget";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./Help.module.scss";

const Help = () => {
  const { t } = useContext(LocalizationContext);
  const [ZeLoaded, setZeLoaded] = useState(false);

  return (
    <Box className={styles.helpContainer}>
      <Box className={styles.helpContent}>
        <Typography variant="h3" className={styles.helpContentTitle}>
          {t("settings.help")}
        </Typography>
        <Box className={styles.tabs}>
          <IconButton
            className={styles.tabOption}
            onClick={showWidgetButton}
            disabled={!ZeLoaded}
          >
            <Typography variant="h6">
              {t("settings.chatWithSupport")}
            </Typography>

            {!ZeLoaded ? (
              <CircularProgress size={20} color="accent" />
            ) : (
              <IconRight className={styles.icon} />
            )}
          </IconButton>
        </Box>
      </Box>
      <ZendeskWidget onLoaded={(val) => setZeLoaded(val)} />
    </Box>
  );
};

export default Help;
