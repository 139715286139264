import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { REWARD_ORDER_STATUS } from "../Constants";
import * as LIMITED_EDITION_API from "../Services/Api/limitedEditionApi";
import { getRewardOrdersVideoCalls } from "../Services/Api/limitedEditionApi";
import * as USER_API from "../Services/Api/userApi";
import { arrayToObjConverter } from "../Utils";

export const createCard = createAsyncThunk(
  "limited-edition/create",
  async (params, { rejectWithValue }) => {
    try {
      const s3Response = await USER_API.generateS3Url({
        type: "customCard",
        ContentType: params.imageFile.type,
        extension: params.imageFile.type.split("/")[1],
      });
      await fetch(s3Response.data.presignedUploadUrl, {
        method: "PUT",
        body: params.imageFile,
      });
      delete params.imageFile;

      const result = await LIMITED_EDITION_API.postCard({
        ...params,
        imageUrl: s3Response.data.presignedUrl,
      });
      return result;
    } catch (e) {
      const error = e?.data || e;
      rejectWithValue(error);
      throw error;
    }
  }
);

export const updateCard = createAsyncThunk(
  "limited-edition/update",
  async (params, { rejectWithValue }) => {
    try {
      let s3Response = params.imageFile;

      if (typeof s3Response !== "string") {
        s3Response = await USER_API.generateS3Url({
          type: "customCard",
          ContentType: params.imageFile.type,
          extension: params.imageFile.type.split("/")[1],
        });
        await fetch(s3Response.data.presignedUploadUrl, {
          method: "PUT",
          body: params.imageFile,
        });
      }
      delete params.imageFile;

      const result = await LIMITED_EDITION_API.patchCard({
        ...params,
        imageUrl: s3Response?.data?.presignedUrl || s3Response,
      });
      return result;
    } catch (e) {
      const error = e?.data || e;
      rejectWithValue(error);
      throw error;
    }
  }
);

export const fetchLECards = createAsyncThunk(
  "get-le/cards",
  async (params, { rejectWithValue }) => {
    try {
      const response = LIMITED_EDITION_API.getCards(params);
      return response;
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const stopSaleLECard = createAsyncThunk(
  "stop-sell-le/card",
  async (params, { rejectWithValue }) => {
    try {
      const response = LIMITED_EDITION_API.stopSaleCard(params);
      return response;
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const fetchRewardOrders = createAsyncThunk(
  "get-rewards-orders/card",
  async (params, { rejectWithValue }) => {
    try {
      const response = await LIMITED_EDITION_API.getRewardOrders(params);
      return response;
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const fetchRewardVideoCallOrders = createAsyncThunk(
  "get-rewards-orders-video-call/card",
  async (params, { rejectWithValue }) => {
    try {
      const response = await LIMITED_EDITION_API.getRewardOrdersVideoCalls(
        params
      );
      return response;
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const fetchRewardOrdersClubPass = createAsyncThunk(
  "get-rewards-orders-club-pass/card",
  async (params, { rejectWithValue }) => {
    try {
      const response = LIMITED_EDITION_API.getRewardOrders(params);
      return response;
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const updateRewardOrder = createAsyncThunk(
  "patch-rewards-orders/card",
  async (params, { rejectWithValue }) => {
    try {
      const response = LIMITED_EDITION_API.patchRewardOrder(params);
      return response;
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const fetchLimitedEditionRewardMessages = createAsyncThunk(
  "le/fetchLimitedEditionRewardMessages",
  async (params, { rejectWithValue }) => {
    try {
      const response = await LIMITED_EDITION_API.getRewardMessages(params);
      return response?.data;
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const postLimitedEditionRewardMessage = createAsyncThunk(
  "le/postLimitedEditionRewardMessage",
  async (params, { rejectWithValue }) => {
    try {
      const response = await LIMITED_EDITION_API.postRewardMessage(params);
      return response?.data;
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

export const patchLimitedEditionStatus = createAsyncThunk(
  "le/patchLimitedEditionStatus",
  async (params, { rejectWithValue }) => {
    try {
      const response = await LIMITED_EDITION_API.patchRewardMessage(params);
      return response;
    } catch (e) {
      return rejectWithValue(e?.data || e);
    }
  }
);

const initialState = {
  cards: {},
  total: 0,
  activeCardId: null,
  currentConstructorCard: null,
  editedCard: null,
  cardDetailsData: null,
  rewardOrders: [],
  rewardOrdersLoading: false,
  rewardOrdersClubPassLoading: false,
  rewardOrdersClubPass: {
    orders: [],
    total: 0,
  },
  rewardOrdersTotal: 0,
  rewardOrdersPage: 1,
  rewardOrdersType: null,
  currentLimitedEditionRewardChat: {
    loading: false,
    messages: [],
    order: {},
  },
};

const limitedEditionSlice = createSlice({
  name: "limitedEdition",
  initialState,
  reducers: {
    updateOrder: (state, action) => {
      const order = action.payload;
      let index = state.rewardOrders.findIndex((item) => item.id === order.id);
      if (index !== -1) {
        state.rewardOrders[index] = { ...state.rewardOrders[index], ...order };
      }
    },
    deleteLimitedEditionCard: (state, action) => {
      if (action.payload.cardId) {
        delete state.cards[action.payload.cardId];
      }
    },
    setActiveLeCardId: (state, action) => {
      state.activeCardId = action.payload;
    },
    setDataToConstructor: (state, action) => {
      state.currentConstructorCard = action.payload;
    },
    setEditedCard: (state, action) => {
      if (action.payload?.id) {
        state.editedCard = {
          ...state.cards[action.payload.id],
          ...action.payload,
        };
      } else {
        state.editedCard = action.payload;
      }
    },
    setCardDetailsData: (state, action) => {
      state.cardDetailsData = action.payload;
    },
    setRewardOrdersPage: (state, action) => {
      state.rewardOrdersPage = action.payload;
    },
    addRewardMessage: (state, action) => {
      if (
        +state.currentLimitedEditionRewardChat.order.id ===
        +action.payload.rewardOrderId
      ) {
        state.currentLimitedEditionRewardChat.messages.push(action.payload);
      }
      const rewordIndex = state.rewardOrders.findIndex(
        (item) => item.id === action.payload.rewardOrderId
      );
      if (state.rewardOrders[rewordIndex]) {
        state.rewardOrders[rewordIndex].status = REWARD_ORDER_STATUS.AWAITING;
        state.rewardOrders[rewordIndex].lastMessage = action.payload;
      }
    },
    resetRewards: (state, action) => {
      state.rewardOrders = [];
      state.rewardOrdersTotal = 0;
      state.rewardOrdersPage = 1;
      state.rewardOrdersType = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCard.fulfilled, (state, action) => {
      const id = action.payload?.data?.id;
      if (id) {
        state.cards[id] = { ...action.payload.data };
      }
    });
    builder.addCase(updateCard.fulfilled, (state, action) => {
      const id = action.payload?.data?.id;
      if (id) {
        state.cards[id] = { ...action.payload.data };
      }
    });
    builder
      .addCase(fetchLECards.fulfilled, (state, action) => {
        const { page } = action.meta.arg;
        if (page === 1) {
          state.cards = arrayToObjConverter({
            arr: action.payload?.cards || [],
            key: "id",
          });
        } else {
          state.cards = {
            ...state.cards,
            ...(arrayToObjConverter({
              arr: action.payload?.cards || [],
              key: "id",
            }) || {}),
          };
        }

        state.total = action.payload?.total || 0;
      })
      .addCase(stopSaleLECard.pending, (state, action) => {
        const { cardId } = action.meta.arg || {};
        if (cardId) {
          state.cards[cardId].isLoading = true;
        }
      })
      .addCase(stopSaleLECard.fulfilled, (state, action) => {
        const { cardId } = action.meta.arg || {};
        if (cardId) {
          state.cards[cardId] = {
            ...state.cards[cardId],
            ...action.payload.data,
            isLoading: false,
          };
        }
      })
      .addCase(stopSaleLECard.rejected, (state, action) => {
        const { cardId } = action.meta.arg || {};
        if (cardId) {
          state.cards[cardId].isLoading = false;
        }
      })
      .addCase(fetchRewardOrders.pending, (state) => {
        state.rewardOrdersLoading = true;
      })
      .addCase(fetchRewardOrders.fulfilled, (state, action) => {
        const { page, type } = action.meta.arg || {};
        const { total = 0, orders = [] } = action.payload || {};
        state.rewardOrdersLoading = false;
        if (!type) {
          state.rewardOrdersTotal = total;
        }
        state.rewardOrdersType = type;
        if (page > 1) {
          state.rewardOrders = state.rewardOrders.concat(orders);
        } else {
          state.rewardOrders = orders;
        }
      })
      .addCase(fetchRewardOrders.rejected, (state) => {
        state.rewardOrdersLoading = false;
      })
      .addCase(fetchRewardVideoCallOrders.pending, (state) => {
        state.rewardOrdersLoading = true;
      })
      .addCase(fetchRewardVideoCallOrders.fulfilled, (state, action) => {
        const { page } = action.meta.arg || {};
        const { total = 0, orders = [] } = action.payload || {};
        state.rewardOrdersLoading = false;
        state.rewardOrdersTotal = total;
        if (page > 1) {
          state.rewardOrders = state.rewardOrders.concat(orders);
        } else {
          state.rewardOrders = orders;
        }
      })
      .addCase(fetchRewardVideoCallOrders.rejected, (state) => {
        state.rewardOrdersLoading = false;
      })
      .addCase(fetchRewardOrdersClubPass.pending, (state) => {
        state.rewardOrdersClubPassLoading = true;
      })
      .addCase(fetchRewardOrdersClubPass.fulfilled, (state, action) => {
        const { total = 0, orders = [] } = action.payload || {};
        state.rewardOrdersClubPassLoading = false;
        state.rewardOrdersClubPass.orders = orders;
        state.rewardOrdersClubPass.total = total;
      })
      .addCase(fetchRewardOrdersClubPass.rejected, (state) => {
        state.rewardOrdersClubPassLoading = false;
      })
      .addCase(fetchLimitedEditionRewardMessages.pending, (state) => {
        state.currentLimitedEditionRewardChat.loading = true;
      })
      .addCase(fetchLimitedEditionRewardMessages.fulfilled, (state, action) => {
        const { orderId } = action.meta.arg || {};
        state.currentLimitedEditionRewardChat.loading = false;
        state.currentLimitedEditionRewardChat.messages =
          action.payload.messages;
        state.currentLimitedEditionRewardChat.order = { id: orderId };
      })
      .addCase(fetchLimitedEditionRewardMessages.rejected, (state) => {
        state.currentLimitedEditionRewardChat.loading = false;
      })
      .addCase(postLimitedEditionRewardMessage.fulfilled, (state, action) => {
        const { index } = action.meta.arg || {};
        if (state.rewardOrders[index]) {
          state.rewardOrders[index].status = REWARD_ORDER_STATUS.PENDING_REPLY;
          state.rewardOrders[index].lastMessage = action.payload;
        }
      })
      .addCase(patchLimitedEditionStatus.fulfilled, (state, action) => {
        const { index, status } = action.meta.arg || {};
        state.rewardOrders[index].status = status;
      });
  },
});
export const {
  deleteLimitedEditionCard,
  setActiveLeCardId,
  setDataToConstructor,
  setEditedCard,
  setCardDetailsData,
  setRewardOrdersPage,
  addRewardMessage,
  resetRewards,
  updateOrder,
} = limitedEditionSlice.actions;
export default limitedEditionSlice.reducer;
