import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as CalendarEditIcon } from "assets/svg/calendar-edit-icon.svg";
import { CALL_STATUS, PATHS, REWARD_ORDER_STATUS } from "Constants/index";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "Redux/appSlice";
import { updateOrder } from "Redux/limitedEditionSlice";
import {
  getScheduledCalls,
  patchScheduledCallsCancel,
  postScheduledCalls,
} from "Services/Api/limitedEditionApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useShowWithDelay } from "Utils/Hooks/useShowWithDelay";
import { getConvertedTimeZoneDate } from "Utils/index";

import { CustomErrorModal } from "../../../Modal/components/CustomErrorModal/CustomErrorModal";
import { UndoSnackbar } from "../../../UndoSnackbar/UndoSnackbar";
import { ScheduledModalFan } from "../ScheduledModalFan/ScheduledModalFan";
import styles from "./ScheduledButton.module.scss";
export const ScheduledButton = ({
  rewardOrderId,
  isShow,
  influencerId,
  appUserId,
  isAbleToEdit,
  duration,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const [calls, setCalls] = useState([]);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [isUndoSnackBar, setUndoSnackBar] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [time, setTime] = useState({ value: "", serverStartDate: null });

  const join = () => {
    if (currentCall?.extMeetingId) {
      navigate(
        PATHS.MEETING_ID.replace(":meetingId", currentCall.extMeetingId)
      );
    }
  };

  const confirm = () => {
    if (isAbleToEdit) {
      setConfirmOpen(true);
    }
  };

  const cancelConfirm = () => {
    setConfirmOpen(false);
  };

  const cancelCall = () => {
    if (currentCall?.id) {
      setConfirmLoader(true);
      patchScheduledCallsCancel({ callId: currentCall.id })
        .then(() => {
          setOpenModal(true);
          setTime(null);
          setStartDate(null);
          setCalls((prevState) =>
            prevState.filter((item) => item.id !== currentCall.id)
          );
          dispatch(
            updateOrder({
              id: rewardOrderId,
              call: { status: CALL_STATUS.CANCELLED },
            })
          );
        })
        .finally(() => {
          setConfirmOpen(false);
          setConfirmLoader(false);
        });
    }
  };

  const getCurrentCall = ({ calls }) => {
    if (calls.length) {
      return calls.reduce((acc, current) => {
        acc =
          new Date(acc.date).getTime() < new Date(current.date).getTime()
            ? acc
            : current;
        return acc;
      }, calls[0]);
    }
  };

  const currentCall = getCurrentCall({ calls });

  const startTimeCall = currentCall?.date;

  const isShowJoinButton = useShowWithDelay({
    checkDelay: 2500,
    targetDate: startTimeCall,
    minutes:
      new Date().getTime() < new Date(startTimeCall) ? 1 : duration || 15,
  });

  const submit = (date) => {
    return postScheduledCalls({
      orderId: rewardOrderId,
      influencerId,
      appUserId,
      date,
    })
      .then((res) => {
        if (res?.call) {
          setCalls((prevState) => [...prevState, res.call]);
          setUndoSnackBar(true);
          dispatch(
            updateOrder({
              id: rewardOrderId,
              call: res.call,
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          setError(e?.response?.data?.message || t("errors.defaultError"))
        );
      });
  };

  useEffect(() => {
    if (rewardOrderId) {
      getScheduledCalls({ orderId: rewardOrderId }).then((res) => {
        if (res?.calls) {
          setCalls(res.calls);
        }
      });
    }
  }, []);

  if (!isShow) {
    return <></>;
  }

  return (
    <Box className={styles.scheduled}>
      {!startTimeCall && isAbleToEdit ? (
        <Button
          variant="contained"
          className={`${styles.scheduledButton} ${styles.scheduledButtonState2} ${styles.scheduledButtonState2Edit}`}
          onClick={() => setOpenModal(true)}
          style={{ cursor: isAbleToEdit ? "pointer" : "initial" }}
        >
          {t("limitedEdition.setVideoCallButton")}
        </Button>
      ) : startTimeCall && !isShowJoinButton ? (
        <Button
          variant="contained"
          className={`${styles.scheduledButton} ${
            styles.scheduledButtonState2
          } ${isAbleToEdit ? styles.scheduledButtonState2Edit : ""}`}
          onClick={confirm}
          style={{ cursor: isAbleToEdit ? "pointer" : "initial" }}
        >
          {t("messages.scheduledState2")}
          <Typography variant="body1">
            {getConvertedTimeZoneDate(startTimeCall, "MMM dd, yyyy HH:mm")}
          </Typography>
          {isAbleToEdit ? <CalendarEditIcon /> : null}
        </Button>
      ) : null}
      {isShowJoinButton ? (
        <Button
          variant="contained"
          className={styles.scheduledButton}
          onClick={join}
        >
          {t("messages.scheduledState3")}
        </Button>
      ) : null}
      <ScheduledModalFan
        isShow={isOpenModal}
        setShow={setOpenModal}
        influencerId={influencerId}
        setTime={setTime}
        time={time}
        startDate={startDate}
        setStartDate={setStartDate}
        submit={submit}
        duration={duration}
      />
      <CustomErrorModal
        isOpen={isConfirmOpen}
        setOpen={setConfirmOpen}
        isAlertIcon={true}
        title={t("messages.rescheduleModalTitle")}
        subtitle={t("messages.rescheduleModalSubTitle")}
      >
        <Button
          className={styles.cancel}
          variant="outlined"
          onClick={cancelConfirm}
        >
          {t("default.cancel")}
        </Button>
        <LoadingButton
          className={styles.submit}
          variant="contained"
          onClick={cancelCall}
          loading={confirmLoader}
        >
          {t("kyc.okay")}
        </LoadingButton>
      </CustomErrorModal>
      <UndoSnackbar
        isOpen={isUndoSnackBar && currentCall}
        onClose={() => setUndoSnackBar(false)}
        undoHandler={cancelCall}
        message={
          <>
            {t("messages.privateCallUndoTitle")}
            <Box>
              {startTimeCall
                ? getConvertedTimeZoneDate(startTimeCall, "MMM dd")
                : null}
            </Box>
          </>
        }
      />
    </Box>
  );
};
