import { useTheme } from "@emotion/react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
import { ReactComponent as IconDelete } from "assets/svg/icon-delete.svg";
import { ReactComponent as IconEdit } from "assets/svg/icon-edit.svg";
import { ReactComponent as IconStop } from "assets/svg/icon-stop.svg";
import CountdownDate from "Components/CountdownDate/CountdownDate";
import CountdownProgress from "Components/CountdownProgress";
import { CustomTooltip } from "Components/CustomTooltip/CustomTooltip";
import { TutorialTooltip } from "Components/TutorialTooltip/TutorialTooltip";
import { UndoSnackbar } from "Components/UndoSnackbar/UndoSnackbar";
import {
  AUTO_HIDE_TIMEOUT,
  CARD_STATUS,
  EDITION_STATUSES,
  PATHS,
  TUTORIALS_PROPS,
} from "Constants/index";
import { REWARDS_ACTION_TYPE } from "Constants/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "Redux/appSlice";
import {
  deleteLimitedEditionCard,
  setCardDetailsData,
  setDataToConstructor,
  setEditedCard,
  updateCard,
} from "Redux/limitedEditionSlice";
import {
  getLECard,
  getLeEditedCard,
} from "Redux/selectors/limitedEditionSelectors";
import { errorResponseMessages } from "Services/Api/apiErrorHelper.tsx";
import { deleteLeCard } from "Services/Api/limitedEditionApi";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import useDelayedExecution from "Utils/Hooks/useDelayedExecution";
import {
  getConvertedFiles,
  getConvertedTimeZoneDate,
  getFileTypeByUrl,
} from "Utils/index";

import { getSeenTutorials } from "../../../../Redux/selectors/userSelector";
import { changeUser, updateUser } from "../../../../Redux/usersSlice";
import styles from "./Card.module.scss";

export const Card = (data) => {
  const {
    imageUrl,
    className,
    dropStartDate,
    createdAt,
    currentSalePrice,
    reviewStatus,
    name,
    status,
    saleTokensLeft,
    quantityAllocated,
    revenue,
    id,
    index,
  } = data;
  const refTooltip = useRef();
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const isS = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { stopExecute, startExecute } = useDelayedExecution(AUTO_HIDE_TIMEOUT);
  const editedCard = useSelector(getLeEditedCard);
  const currentCard = useSelector(getLECard(id));
  const seenTutorials = useSelector(getSeenTutorials);

  const [isDeleteSnackbarActive, setDeleteSnackbarActive] = useState(false);
  const [isEditSnackbarActive, setEditSnackbarActive] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [isOpenCustomTooltip, setOpenCustomTooltip] = useState(false);
  const [isFirstImgLoaded, setFirstImgLoaded] = useState(false);

  const statusTitle = {
    [CARD_STATUS.NEW]: t("default.cardStatusInReview"),
    [CARD_STATUS.REJECTED]: t("default.cardStatusFailedReview"),
  };

  const isDropp =
    reviewStatus === CARD_STATUS.APPROVED && status === EDITION_STATUSES.phase0;
  const isTokenLeft =
    reviewStatus === CARD_STATUS.APPROVED &&
    (status === EDITION_STATUSES.phase2 ||
      status === EDITION_STATUSES.complete);
  const isRevenue =
    reviewStatus === CARD_STATUS.APPROVED &&
    (status === EDITION_STATUSES.phase2 ||
      status === EDITION_STATUSES.complete);

  const getFormat = (startDate) => {
    const date = new Date(startDate) - new Date();
    const hours = date / 1000 / 60 / 60;
    return hours >= 24
      ? ["days", "hours", "minutes"]
      : ["days", "hours", "minutes", "seconds"];
  };

  const handleDeleteCard = () => {
    setDeleteSnackbarActive(true);
    startExecute(() => {
      dispatch(deleteLimitedEditionCard({ cardId: id }));
      deleteLeCard({ cardId: id }).catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              title: t("errors.error"),
              subtitle: errorResponseMessages(err, t),
            })
          );
        }
      });
    });
  };

  const undoDeleteHandler = () => {
    setDeleteSnackbarActive(false);
    stopExecute();
    setLoader(false);
  };

  const undoEditHandler = () => {
    setEditSnackbarActive(false);
    dispatch(setEditedCard(null));
    stopExecute();
    setLoader(false);
  };

  const handleEditCard = () => {
    dispatch(setDataToConstructor(data));
    const path = PATHS.CARD_CONSTRUCTOR_EDIT.replace(":id", data.id);
    navigate(path);
  };

  const goToCard = (e) => {
    if (
      !refTooltip.current?.contains(e.target) &&
      !e.target.classList.contains("MuiTooltip-tooltip") &&
      !e.target.classList.contains(styles.popoverItem)
    ) {
      const path = PATHS.LIMITED_EDITION_CARD.replace(":id", id);
      dispatch(setCardDetailsData(data));
      navigate(path);
    }
  };

  const handleStopSaleAlert = () => {
    dispatch(
      setError({
        open: true,
        title: t("limitedEdition.stopSaleCardAlertTitle"),
        subtitle: t("limitedEdition.stopSaleCardAlertSubTitle"),
        actions: "StopCardSaleAction",
        cardId: id,
      })
    );
  };

  const imgLoad = () => {
    if (index === 0 && reviewStatus === CARD_STATUS.NEW) {
      setTimeout(() => {
        setFirstImgLoaded(true);
      }, 2000);
    }
  };

  const closeTutorialTooltip = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [TUTORIALS_PROPS.FIRST_CARD]: true,
      },
    };
    dispatch(changeUser(params));
    dispatch(updateUser(params));
  };

  useEffect(() => {
    if (+editedCard?.id === id) {
      const handler = async () => {
        setEditSnackbarActive(true);
        setLoader(true);
        let media = null;
        if (editedCard.media?.length) {
          const mediaData = editedCard.media.reduce(
            (acc, current) => {
              if (current.cardId) {
                acc.oldMedia.push(current.url);
              } else {
                acc.blobFiles.push(current);
              }
              return acc;
            },
            {
              blobFiles: [],
              oldMedia: [],
            }
          );
          if (mediaData.blobFiles.length) {
            mediaData.blobFiles = await getConvertedFiles({
              blobFiles: editedCard.media.filter((item) => !item.cardId),
            });
          }
          media = [...mediaData.oldMedia, ...mediaData.blobFiles].map(
            (item, index) => ({
              index,
              url: item,
              type: getFileTypeByUrl(item),
            })
          );
        }
        startExecute(() => {
          dispatch(
            updateCard({
              cardId: editedCard.id,
              name: editedCard.name,
              description: editedCard.description,
              imageFile: editedCard.imageFile || editedCard.imageUrl,
              salePrice: editedCard.salePrice || editedCard.currentSalePrice,
              allocation: editedCard.allocation || editedCard.quantityAllocated,
              dropDate: editedCard.dropDate || editedCard.dropStartDate,
              ...(editedCard.duration && {
                duration: editedCard.duration,
              }),
              media:
                media?.length &&
                editedCard.rewardType === REWARDS_ACTION_TYPE.HIDDEN_CONTENT
                  ? media
                  : [],
              rewardType:
                editedCard.rewardType &&
                (media?.length || editedCard.rewardMessage?.trim().length)
                  ? editedCard.rewardType
                  : null,
              rewardMessage:
                (editedCard.rewardMessage?.trim().length &&
                  editedCard.rewardType === REWARDS_ACTION_TYPE.PREMIUM_CHAT) ||
                editedCard.rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK ||
                editedCard.rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL
                  ? editedCard.rewardMessage
                  : "",
            })
          )
            .catch((err) => {
              dispatch(
                setError({
                  open: true,
                  title: t("errors.error"),
                  subtitle: err?.data?.message,
                })
              );
            })
            .then((res) => {
              if (res?.error?.message) {
                dispatch(
                  setError({
                    open: true,
                    title: t("errors.error"),
                    subtitle: res.error.message,
                  })
                );
              }
            })
            .finally(() => {
              dispatch(setEditedCard(null));
              setEditSnackbarActive(false);
              setLoader(false);
            });
        });
      };
      handler();
    }
  }, [editedCard]);

  return (
    <>
      <Box className={`${styles.card} ${className}`} onClick={goToCard}>
        {isDeleteSnackbarActive || isLoader || currentCard.isLoading ? (
          <Box className={styles.cardLoader}>
            <CircularProgress />
          </Box>
        ) : null}
        <Box className={styles.cardImgWrapper}>
          <img src={imageUrl} alt="card" onLoad={imgLoad} />
        </Box>
        <Box className={styles.cardMain}>
          <Box className={styles.title}>
            <Typography variant="body3_display_medium">
              {getConvertedTimeZoneDate(dropStartDate)}
            </Typography>
            <Typography variant="body3_display_medium">
              ${currentSalePrice}
            </Typography>
          </Box>
          {reviewStatus !== CARD_STATUS.APPROVED ? (
            <Box style={{ position: "relative" }}>
              <TutorialTooltip
                isOpen={
                  !seenTutorials?.[TUTORIALS_PROPS.FIRST_CARD] &&
                  isFirstImgLoaded
                }
                setOpen={closeTutorialTooltip}
                placement={isS ? "bottom" : "right"}
                title={t("limitedEdition.cardTutorialTooltipTitle")}
                subTitle={t("limitedEdition.cardTutorialTooltipSubTitle")}
              >
                <Typography
                  variant="body3_medium"
                  component="p"
                  className={`${styles.cardStatus} ${styles[reviewStatus]}`}
                >
                  {statusTitle[reviewStatus]}
                </Typography>
              </TutorialTooltip>
            </Box>
          ) : null}
          {isDropp ? (
            <>
              <Box className={styles.droppingInBlock}>
                <Typography variant="text_12_display_bold">
                  {t("seasons.droppingIn")}
                </Typography>
                <Typography variant="text_12_display_bold">
                  <CountdownDate
                    endDate={dropStartDate}
                    hoursStyling={{
                      variant: "text_12_display_bold",
                      color: theme.palette.tertiary.main,
                    }}
                    divider=":"
                    timerFormat={{
                      format: getFormat(dropStartDate),
                    }}
                  />
                </Typography>
              </Box>
              <CountdownProgress
                className={styles.droppingInTimer}
                startDate={createdAt}
                endDate={dropStartDate}
                color={theme.palette.tertiary}
              />
            </>
          ) : null}
          {isTokenLeft ? (
            <Box className={styles.row}>
              <Typography variant="body3_display_medium">
                {t("seasons.available")}
              </Typography>
              <Typography
                variant="body3_display_medium"
                style={{ color: theme.palette.lime.main }}
              >
                {status === EDITION_STATUSES.phase2
                  ? `${saleTokensLeft}/${quantityAllocated}`
                  : t("seasons.soldOut")}
              </Typography>
            </Box>
          ) : null}
          {isRevenue ? (
            <Box className={styles.row}>
              <Typography variant="body3_display_medium">
                {t("seasons.revenue")}
              </Typography>
              <Typography
                variant="body3_display_medium"
                style={{ color: theme.palette.chart.second }}
              >
                ${revenue || 0}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box className={styles.cardDescriptionWrapper}>
          <Typography className={styles.cardDescription} variant="body1">
            {name}
          </Typography>
          {reviewStatus !== CARD_STATUS.APPROVED ? (
            <Box ref={refTooltip}>
              <CustomTooltip
                title={
                  <Box>
                    <Typography
                      variant="body2"
                      className={styles.popoverItem}
                      onClick={handleEditCard}
                    >
                      <IconEdit />
                      {t("limitedEdition.editCard")}
                    </Typography>
                    <Typography
                      variant="body3_medium_14_display"
                      onClick={handleDeleteCard}
                      className={`${styles.red} ${styles.popoverItem}`}
                    >
                      <IconDelete /> {t("limitedEdition.deleteCard")}
                    </Typography>
                  </Box>
                }
                isClick={true}
                placement="top-end"
                setOpen={setOpenCustomTooltip}
                open={isOpenCustomTooltip}
              >
                <Button>
                  <DotsEditIcon />
                </Button>
              </CustomTooltip>
            </Box>
          ) : status === EDITION_STATUSES.phase2 ? (
            <Box ref={refTooltip}>
              <CustomTooltip
                title={
                  <Box>
                    <Typography
                      variant="body3_medium_14_display"
                      onClick={handleStopSaleAlert}
                      className={`${styles.red} ${styles.popoverItem}`}
                    >
                      <IconStop /> {t("limitedEdition.stopSaleCard")}
                    </Typography>
                  </Box>
                }
                isClick={true}
                placement="top-end"
                setOpen={setOpenCustomTooltip}
                open={isOpenCustomTooltip}
              >
                <Button>
                  <DotsEditIcon />
                </Button>
              </CustomTooltip>
            </Box>
          ) : null}
        </Box>
      </Box>
      <UndoSnackbar
        message={t("default.successDeleteCardMessage")}
        isOpen={isDeleteSnackbarActive}
        autoHideDuration={AUTO_HIDE_TIMEOUT}
        onClose={() => setDeleteSnackbarActive(false)}
        undoHandler={undoDeleteHandler}
      />
      <UndoSnackbar
        message={t("default.successEditCardMessage")}
        isOpen={isEditSnackbarActive}
        autoHideDuration={AUTO_HIDE_TIMEOUT}
        onClose={() => setEditSnackbarActive(false)}
        undoHandler={undoEditHandler}
      />
    </>
  );
};
