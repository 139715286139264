import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import React from "react";

import styles from "./CustomTooltip.module.scss";

export const CustomTooltip = ({
  title,
  children,
  isArrow,
  placement = "top",
  isClick,
  isCloseOutside = true,
  tooltipClassName = "",
  customTooltipArrow = "",
  isDisabled = false,
  childrenClassName,
  shouldHideTooltip,
  setOpen,
  open,
  ...props
}) => {
  const handleTooltipClose = () => {
    if (isCloseOutside) {
      setOpen(false);
    }
  };

  const handleTooltipOpen = (e) => {
    e.stopPropagation();
    if (setOpen) {
      setOpen(true);
    }
  };

  if (isDisabled) {
    return <>{children}</>;
  }

  if (isClick) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box>
          <Tooltip
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={shouldHideTooltip ? "" : title}
            arrow={isArrow}
            classes={{
              tooltip: `${styles.customTooltip} ${
                tooltipClassName ? tooltipClassName : ""
              }`,
              tooltipArrow: `${styles.customTooltip} ${
                tooltipClassName ? tooltipClassName : ""
              }`,
              arrow: `${styles.customTooltipArrow} ${customTooltipArrow}`,
            }}
            placement={placement}
            {...props}
          >
            <Box onClick={handleTooltipOpen} className={childrenClassName}>
              {children}
            </Box>
          </Tooltip>
        </Box>
      </ClickAwayListener>
    );
  }

  return (
    <Tooltip
      title={shouldHideTooltip ? "" : title}
      placement={placement}
      arrow={isArrow}
      classes={{
        tooltipArrow: `${styles.customTooltip} ${tooltipClassName}`,
        tooltip: `${styles.customTooltip} ${tooltipClassName}`,
        arrow: styles.customTooltipArrow,
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};
