import { Box, Button, IconButton, Typography } from "@mui/material";
import { ReactComponent as IconKebab } from "assets/svg/icon-kebab.svg";
import { ReactComponent as Pin } from "assets/svg/pin-icon.svg";
import Poll from "Components/Poll/Poll";
import { LEVEL_GROUP } from "Constants/index";
import { format, isToday } from "date-fns";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getMainAppUserId } from "Redux/selectors/mainAppUserSelector";
import {
  getUserLevel,
  getUserName,
  getUserProfileImgUrl,
} from "Redux/selectors/userSelector";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { formatPostMessage } from "Utils";

import { AudioBlock } from "../AudioBlock/AudioBlock";
import styles from "./CommunityPostHeader.module.scss";

const CommunityPostHeader = ({
  setAnchorEl,
  message,
  createdAt,
  isPinnedPost,
  audios,
  postIndex,
  inReview,
  appUser,
  openPost,
  poll,
}) => {
  const { t } = useContext(LocalizationContext);
  const textRef = useRef();

  const mainAppUserId = useSelector(getMainAppUserId);
  const name = useSelector(getUserName);
  const profileImageUrl = useSelector(getUserProfileImgUrl);
  const level = useSelector(getUserLevel);

  const [isTruncated, setIsTruncated] = useState(false);
  const [lineHeight, setLineHeight] = useState(0);
  const MAX_LINES = 3;
  const lang = localStorage.getItem("language") || "en-US";

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const showHandler = (e) => {
    e.stopPropagation();
    textRef.current.style.display = "block";
    setIsTruncated(false);
  };

  useLayoutEffect(() => {
    const textBlock = textRef.current;
    if (textBlock) {
      const computedStyle = window.getComputedStyle(textBlock);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      setLineHeight(lineHeight);

      const lines = Math.floor(textBlock.offsetHeight / lineHeight);
      if (lines > MAX_LINES) {
        setIsTruncated(true);
      }
    }
  }, []);

  return (
    <Box className={styles.header}>
      <AudioBlock audios={audios} postIndex={postIndex} />
      <Box className={styles.headerTop}>
        <Box className={styles.headerTopTitle}>
          <>
            {level !== LEVEL_GROUP ||
            mainAppUserId === appUser?.id ||
            !appUser?.id ? (
              <img alt="avatar" src={profileImageUrl} />
            ) : appUser?.profileImageUrl ? (
              <img
                alt="avatar"
                src={appUser?.profileImageUrl}
                className={level === LEVEL_GROUP ? styles.avatarCircle : ""}
              />
            ) : null}
          </>
          <Typography variant="body3_medium_14_display">
            {level !== LEVEL_GROUP ||
            mainAppUserId === appUser?.id ||
            !appUser?.id
              ? name
              : appUser?.username
              ? appUser.username
              : ""}
          </Typography>
        </Box>
        {inReview ? (
          <Button className={styles.statusButton} onClick={openPost}>
            <Typography
              variant="body3_medium"
              component="span"
              className={styles.statusButtonTitle}
            >
              {t("default.statusPendingReview")}
            </Typography>
            <svg
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4911_23572)">
                <path
                  d="M6.5 12.5C9.53757 12.5 12 10.0376 12 7C12 3.96243 9.53757 1.5 6.5 1.5C3.46243 1.5 1 3.96243 1 7C1 10.0376 3.46243 12.5 6.5 12.5Z"
                  stroke="#111322"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.5 3.94238V8.58072"
                  stroke="#111322"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.5 9.70646V9.57812"
                  stroke="#111322"
                  strokeWidth="1.2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_4911_23572">
                  <rect width="13" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Button>
        ) : null}
        <IconButton onClick={handleClick} className={styles.iconWrapperKebab}>
          <IconKebab />
        </IconButton>
      </Box>
      {poll?.question ? (
        <Typography variant="body3_medium" className={styles.pollQuestion}>
          {poll?.question}
        </Typography>
      ) : null}
      {message ? (
        <Typography
          variant="body18"
          className={`${styles.commentContent} ql-editor ${
            isTruncated ? styles.isTruncated : ""
          }`}
          style={{
            maxHeight: isTruncated ? `${MAX_LINES * lineHeight}px` : "none",
          }}
          ref={textRef}
          onClick={showHandler}
        >
          {formatPostMessage(message)}
        </Typography>
      ) : null}
      {isTruncated && (
        <Typography
          valiant="body3"
          className={styles.headerSeeFullMessage}
          onClick={showHandler}
        >
          {t("community.seeFullMessage")}
        </Typography>
      )}
      <Poll data={poll} />
      <Box className={styles.headerAdditionalInfo}>
        {isPinnedPost ? (
          <Box className={styles.headerPinnedWrapper}>
            <Pin />
            <Typography
              variant="body2"
              component={"span"}
              className={styles.headerPinned}
            >
              {t("community.pinned")}
            </Typography>
          </Box>
        ) : (
          <Box />
        )}
        <Box className={styles.bottomInfo}>
          {poll ? (
            <Box className={styles.pollInfo}>
              <Typography valiant="body3" className={styles.headerDate}>
                {poll?.totalVotes === 1
                  ? t("community.pollVotesSingular", {
                      number: poll?.totalVotes,
                    })
                  : t("community.pollVotes", { number: poll?.totalVotes || 0 })}
              </Typography>
              {poll?.deadline &&
              new Date(poll.deadline).getTime() < Date.now() ? (
                <>
                  <span className={styles.durationSpacing}></span>
                  <Typography valiant="body3" className={styles.headerDate}>
                    {t("default.pollIsClosed")}
                  </Typography>
                </>
              ) : poll?.duration ? (
                <Typography valiant="body3" className={styles.headerDate}>
                  <span className={styles.durationSpacing}></span>
                  {poll?.duration === 1
                    ? t("community.pollVotingDurationSingular", {
                        duration: poll?.duration,
                      })
                    : t("community.pollVotingDuration", {
                        duration: poll?.duration,
                      })}
                </Typography>
              ) : null}
            </Box>
          ) : null}
          <Typography valiant="body3" className={styles.headerDate}>
            {isToday(new Date(createdAt))
              ? format(new Date(createdAt), "HH:mm")
              : new Date(createdAt).toLocaleDateString(lang, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CommunityPostHeader;
