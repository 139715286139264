import { MEDIA_TYPES, MEDIA_URLS_PROP } from "../../Constants";
import { api, mainApi } from "./api.service";
import { generateS3Url } from "./userApi";

const API_URL = process.env.REACT_APP_API_HOST;
const MAIN_API_URL = process.env.REACT_APP_MAIN_API_HOST;

export const getPosts = async (params) => {
  const response = await api.get(`${API_URL}/me/posts`, { params });
  return response.data;
};

export const postApproveAll = async (params) => {
  const response = await api.post(
    `${API_URL}/posts/approve-all
`,
    { params }
  );
  return response.data;
};

export const postRejectAll = async (params) => {
  const response = await api.post(`${API_URL}/posts/reject-all`, { params });
  return response.data;
};

export const getFans = async ({ page = 1, limit = 10, ...params }) => {
  const response = await api.get(
    `${API_URL}/me/fans?limit=${limit}&page=${page}`,
    { params }
  );
  return response.data;
};

export const patchAllowPostingFan = async ({ appUserId, index, ...rest }) => {
  try {
    const response = await api.patch(
      `${API_URL}/me/fans/${appUserId}/posting-rights`,
      { ...rest }
    );

    return response.data;
  } catch (e) {
    throw new Error(e.data.message);
  }
};

export const getPostById = async ({ postId }) => {
  const response = await api.get(`${API_URL}/posts/${postId}`);
  return response.data;
};

const getParsedFiles = (files, urlType) => {
  return files.reduce(
    (prev, acc) => {
      if (acc.file) {
        prev.blobFiles.push(acc);
      } else {
        prev.urls.push(acc[urlType]);
      }
      return prev;
    },
    {
      blobFiles: [],
      urls: [],
    }
  );
};

const getConvertedFiles = async ({ blobFiles, mediaType }) => {
  const promises = blobFiles.map(async (item) => {
    const s3Response = await generateS3Url({
      type: mediaType,
      ContentType: item.file.type,
      extension: item.file.type.split("/")[1],
    });
    await fetch(s3Response.data.presignedUploadUrl, {
      method: "PUT",
      body: item.file,
    });

    return s3Response.data.presignedUrl;
  });

  const result = await Promise.all(promises);
  return result;
};

export const patchPost = async ({ postId, ...rest }) => {
  try {
    const response = await api.patch(`${API_URL}/posts/${postId}`, { ...rest });

    return response.data;
  } catch (e) {
    throw new Error(e.data.message);
  }
};

export const createPost = async ({
  files = [],
  mediaType,
  youtubeUrl,
  scheduledAt,
  question,
  options,
  duration,
  ...rest
}) => {
  const result = await getConvertedFiles({ blobFiles: files, mediaType });
  const response = await api.post(`${API_URL}/posts`, {
    type: mediaType || MEDIA_TYPES.TEXT,
    ...(mediaType === MEDIA_TYPES.IMAGE && {
      images: result.map((imageUrl, index) => ({
        index,
        imageUrl,
      })),
    }),
    ...(mediaType === MEDIA_TYPES.VIDEO && {
      videos: [
        {
          index: 0,
          videoUrl: result[0],
        },
      ],
    }),
    ...(mediaType === MEDIA_TYPES.AUDIO && {
      audios: result.map((audioUrl, index) => ({
        index,
        audioUrl,
      })),
    }),
    ...(youtubeUrl && { youtubeUrl }),
    ...(question && options && { question, options, duration, hasPoll: true }),
    ...(scheduledAt && { scheduledAt: new Date(scheduledAt) }),
    ...rest,
  });

  return response.data;
};

export const updatePost = async ({
  postId,
  files,
  mediaType,
  youtubeUrl,
  createdAt,
  scheduledAt,
  status,
  question,
  options,
  duration,
  ...rest
}) => {
  const urlType =
    mediaType === MEDIA_TYPES.IMAGE
      ? MEDIA_URLS_PROP.imageUrl
      : mediaType === MEDIA_TYPES.AUDIO
      ? MEDIA_URLS_PROP.audioUrl
      : MEDIA_TYPES.VIDEO
      ? MEDIA_URLS_PROP.videoUrl
      : null;
  const { blobFiles, urls } = getParsedFiles(files, urlType);
  const result = await getConvertedFiles({ blobFiles, mediaType });
  const allFiles = [...result, ...urls];

  const response = await api.put(`${API_URL}/posts/${postId}`, {
    type: mediaType || MEDIA_TYPES.TEXT,
    ...(mediaType === MEDIA_TYPES.IMAGE && {
      images: allFiles.map((imageUrl, index) => ({
        index,
        imageUrl,
      })),
    }),
    ...(mediaType === MEDIA_TYPES.VIDEO && {
      videos: [
        {
          index: 0,
          videoUrl: allFiles[0],
        },
      ],
    }),
    ...(mediaType === MEDIA_TYPES.AUDIO && {
      audios: allFiles.map((audioUrl, index) => ({
        index,
        audioUrl,
      })),
    }),
    ...(youtubeUrl && { youtubeUrl }),
    ...(createdAt && { createdAt }),
    ...(status && { status }),
    ...(scheduledAt && { scheduledAt: new Date(scheduledAt) }),
    ...(question && options && { question, options, duration, hasPoll: true }),
    ...rest,
  });
  return response.data;
};

export const deletePost = async ({ postId }) => {
  try {
    const response = await api.delete(`${API_URL}/posts/${postId}`);
    return response.data;
  } catch (e) {
    throw new Error(e.data[0].message);
  }
};

export const getPostComments = async ({ postId, limit = 1, page = 1 }) => {
  const response = await api.get(
    `${API_URL}/posts/${postId}/comments?limit=${limit}&page=${page}`
  );
  return response.data;
};

export const sendMessage = async ({ postId, ...params }) => {
  try {
    const response = await api.post(`${API_URL}/posts/${postId}/comments`, {
      ...params,
    });

    return response.data;
  } catch (e) {
    throw new Error(e.data.message);
  }
};

export const pinPost = async ({ postId }) => {
  try {
    const response = await api.post(`${API_URL}/posts/${postId}/pin`);

    return response.data;
  } catch (e) {
    throw new Error(e.data.message);
  }
};

export const unpinPost = async ({ postId }) => {
  try {
    const response = await api.delete(`${API_URL}/posts/${postId}/pin`);

    return response.data;
  } catch (e) {
    throw new Error(e.data.message);
  }
};

export const stripeAccountLink = async () => {
  try {
    const response = await api.post(`${API_URL}/payments/stripe-account-link`);
    return response.data;
  } catch (e) {
    throw new Error(e?.data?.message);
  }
};

export const stripeLoginLink = async () => {
  try {
    const response = await api.post(`${API_URL}/payments/stripe-login-link`);
    return response.data;
  } catch (e) {
    throw new Error(e?.data?.message);
  }
};

export const sendPostReaction = async (postId, reactionId) => {
  try {
    return api.post(`${API_URL}/posts/${postId}/reactions`, { reactionId });
  } catch (e) {
    console.error(e);
  }
};

export const deletePostReaction = async (postId, reactionId) => {
  try {
    const response = await api.delete(
      `${API_URL}/posts/${postId}/reactions/${reactionId}`
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getReactions = async () => {
  try {
    const response = await api.get(`${API_URL}/reactions`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getComments = async ({ id, params }) => {
  const response = await api.get(`${API_URL}/posts/${id}/comments`, {
    params: params,
  });
  return response.data;
};

export const postCommentLike = async (id) => {
  try {
    const response = await api.post(`${API_URL}/comments/${id}/like`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};
export const deleteCommentLike = async (id) => {
  try {
    const response = await api.delete(`${API_URL}/comments/${id}/like`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const deleteComment = async ({ postId, commentId }) => {
  const response = await api.delete(
    `${API_URL}/posts/${postId}/comments/${commentId}`
  );

  return response.data;
};

export const updateComment = async ({ postId, commentId, message }) => {
  const response = await api.patch(
    `${API_URL}//posts/${postId}/comments/${commentId}`,
    {
      message,
    }
  );

  return response.data;
};

export const postReport = async ({ type, recordId, contentType }) => {
  try {
    const response = await mainApi.post(`${MAIN_API_URL}/reports`, {
      contentType,
      type,
      recordId,
    });
    return response.data;
  } catch (e) {
    console.error(e);
  }
};
