import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import Page from "Components/Page/Page";
import SidebarTablet from "Components/SidebarTablet/SidebarTablet";
import ZendeskWidget, { showWidgetButton } from "Components/ZendeskWidget";
import { BLOCK_REASON_KYC, PATHS, USER_BLOCKED } from "Constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader, setOnfidoToken } from "Redux/appSlice";
import { fetchUser } from "Redux/mainAppUserSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { CheckKycStatus } from "Utils/Hooks/kycCheck";

import styles from "./AccountSuspended.module.scss";

export const AccountSuspended = () => {
  const { t } = useContext(LocalizationContext);
  const onfidoError = useSelector((state) => state.mainAppUser?.error);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isKYC, setKYC] = useState(false);
  const [processing, setProcessing] = useState(false);

  const checkStatus = CheckKycStatus();

  const getKycStatus = async () => {
    try {
      dispatch(setLoader(true));
      const res = await checkStatus();
      dispatch(setLoader(false));
      if (res === 1) return navigate(PATHS.COMMUNITY);
      return res?.sdkToken;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const [ZeLoaded, setZeLoaded] = useState(false);

  const launchOnfidoKyc = () => {
    setProcessing(true);
    try {
      getKycStatus()
        .then((sdkToken) => {
          if (sdkToken) {
            dispatch(
              setOnfidoToken({
                sdkToken: sdkToken,
                onfidoRedirectComplete: PATHS.COMMUNITY,
              })
            );
          }
        })
        .finally(() => setProcessing(false));
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  useEffect(() => {
    if (
      onfidoError?.type === USER_BLOCKED &&
      onfidoError?.message === BLOCK_REASON_KYC
    ) {
      setKYC(true);
    }
  }, [onfidoError?.message, onfidoError?.type]);

  return (
    <Page>
      <Box className={styles.titleWrap}>
        <SidebarTablet />
        <Typography variant="h2">{t("default.accountSuspended")}</Typography>
      </Box>
      <Box className={styles.main}>
        <Typography variant="h3" className={styles.subtitle}>
          {isKYC ? t("default.kycVerifyTitle") : t("default.suspendedTitle")}
        </Typography>
        {isKYC ? (
          <LoadingButton
            variant="contained"
            size="small"
            onClick={launchOnfidoKyc}
            loading={processing}
          >
            <Typography variant="body2">
              {t("default.continueVerification")}
            </Typography>
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="red"
            size="small"
            onClick={showWidgetButton}
            loading={!ZeLoaded}
          >
            <Typography variant="body2">
              {t("default.talkToSupport")}
            </Typography>
          </LoadingButton>
        )}
      </Box>
      <ZendeskWidget onLoaded={(val) => setZeLoaded(val)} />
    </Page>
  );
};
