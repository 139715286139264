import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as UploadIconBtn } from "assets/svg/account/upload-photo-btn.svg";
import { ReactComponent as ArrowDown } from "assets/svg/arrow-down.svg";
import { ReactComponent as UploadIcon } from "assets/svg/icon-upload.svg";
import classNames from "classnames/bind";
import ModalUploadPhoto from "Components/Modal/ModalUploadPhoto";
import Page from "Components/Page/Page";
import {
  getCategories,
  LEVEL_SMALLER,
  MainAppDomain,
  PATHS,
  POST_IN_GROUPS,
  TUTORIALS_PROPS,
  USER_ME_STATUS,
} from "Constants";
import { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "Redux/appSlice";
import {
  changeUser,
  editCommunity,
  updateUser,
  uploadPhoto,
} from "Redux/usersSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import * as Yup from "yup";

import { CustomAlert } from "../../Components/CustomAlert/CustomAlert";
import { ShareBtn } from "../../Components/ShareBtn/ShareBtn";
import SidebarTablet from "../../Components/SidebarTablet/SidebarTablet";
import { fetchUser } from "../../Redux/mainAppUserSlice";
import { getUsersMe } from "../../Redux/selectors/userSelector";
import { FormErrorMessage } from "./FormErrorMessage";
import { CopyIcon } from "./icons";
import styles from "./Profile.module.scss";
import SocialMediaLinks from "./SocialMediaLinks";

const Profile = () => {
  const { t } = useContext(LocalizationContext);
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t("errors.required")).nullable(),
    handle: Yup.string()
      .trim()
      .required(t("errors.required"))
      .matches(/^[a-zA-Z0-9_@.]+$/, t("errors.handleError"))
      .nullable(),
    about: Yup.string()
      .trim()
      .max(500, t("errors.aboutMaxChar"))
      .test(
        "maxLines",
        t("errors.aboutMaxLines"),
        (value) => (value.match(/^.*(\r\n|\r|\n)?$/gm) || []).length <= 11
      )
      .nullable(),
    category: Yup.string().notRequired(),
  });
  const [openUploadPhoto, setOpenUploadPhoto] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [allowPosting, setAllowPosting] = useState(POST_IN_GROUPS.ONLY_ME);

  const user = useSelector(getUsersMe);

  const {
    about = "",
    category = null,
    name = "",
    handle = "",
    seenTutorials = null,
  } = user;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUploadPhotoOpen = () => setOpenUploadPhoto(true);
  const handleUploadPhotoClose = () => setOpenUploadPhoto(false);

  const handleUploadPhoto = (payload) => {
    dispatch(uploadPhoto(payload)).then(() => {
      handleUploadPhotoClose();
    });
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    setFocus,
    formState: { errors, isSubmitted },
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { name: nameError, about: aboutError, handle: handleError } = errors;

  const headerTitle =
    user?.status === USER_ME_STATUS.NEW
      ? t("account.profilePageHeader")
      : t("account.profile");

  const hasTwoLinks =
    inputFields.filter((item) => item.link.trim().length).length >= 2;

  const isProfilePictureEmpty =
    !user?.profileImageUrl || user?.profileImageUrl?.includes("default");

  const closeAlertHandler = () => {
    const params = {
      seenTutorials: {
        ...seenTutorials,
        [TUTORIALS_PROPS.PROFILE_COMPLETE]: true,
      },
    };
    dispatch(changeUser(params));
    dispatch(updateUser(params));
  };
  const formTop = useRef(null);
  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    if (firstError) {
      setFocus(firstError);
    }
    if (isSubmitted && isProfilePictureEmpty) formTop.current.scrollIntoView();
  }, [errors, isProfilePictureEmpty, isSubmitted, setFocus]);

  useEffect(() => {
    if (user?.community?.postingRights) {
      setAllowPosting(user.community.postingRights);
    }
  }, [user?.community?.postingRights]);

  useEffect(() => {
    setValue("about", about || "");
  }, [about]);

  useEffect(() => {
    setValue("category", category || "none");
  }, [category]);

  useEffect(() => {
    setValue("name", name);
  }, [name]);

  useEffect(() => {
    setValue("handle", handle);
  }, [handle]);

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  const onSubmit = (data) => {
    if (!hasTwoLinks || isProfilePictureEmpty) return;
    setLoading(true);
    const newData = { ...data };
    if (newData.category === "none") {
      delete newData.category;
    }
    newData.level = LEVEL_SMALLER;
    dispatch(
      updateUser({
        ...newData,
        ...{
          socialMedia: inputFields.reduce((acc, item) => {
            item.link &&
              acc.push({
                socialPlatformId: item.socialPlatformId,
                link: item.link,
              });
            return acc;
          }, []),
        },
      })
    )
      .then((res) => {
        setLoading(false);
        if (res?.payload?.status === 400) {
          dispatch(
            setSnackbar({
              message: t("account.errorHandle"),
              open: true,
              right: "20px",
              left: "unset",
              severity: "error",
            })
          );
          setError("handle", t("account.errorHandle"));
        } else {
          dispatch(
            setSnackbar({
              message: t("account.savedSuccess"),
              open: true,
              right: "20px",
              left: "unset",
            })
          );
          if (allowPosting !== POST_IN_GROUPS.SELECTED_MEMBERS) {
            dispatch(editCommunity({ postingRights: allowPosting }));
          }
          if (user?.status === USER_ME_STATUS.NEW) {
            navigate(PATHS.COMMUNITY);
            //ToDo: hot solution, need to reload page to connect ws
            setTimeout(() => {
              window.location.reload();
            }, 0);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const cx = classNames.bind(styles);
  return (
    <Page className={styles.container}>
      <Box className={styles.titleWrap} ref={formTop}>
        <SidebarTablet />
        <Typography variant="h2" className={styles.title}>
          {headerTitle}
        </Typography>
      </Box>
      <Box>
        <CustomAlert
          severity="info"
          title={t("account.profileSetupAlert")}
          classNameWrapper={styles.alert}
          classNameTitle={styles.alertTitle}
          isShow={
            user?.status === USER_ME_STATUS.NEW &&
            !seenTutorials?.[TUTORIALS_PROPS.PROFILE_COMPLETE]
          }
          onClose={closeAlertHandler}
        />
        {user && (
          <>
            <Box className={styles.mainInfoWrapper}>
              <Box className={styles.mainInfo}>
                <Box className={styles.avatarWrap}>
                  <Avatar
                    variant="square"
                    src={user?.profileImageUrl}
                    className={cx(styles.profileImage, {
                      [styles.profileImageError]:
                        isProfilePictureEmpty && isSubmitted,
                    })}
                    onClick={handleUploadPhotoOpen}
                  />
                  <Box className={styles.profileImageOverlay}>
                    <UploadIcon width={24} height={24} />
                  </Box>
                </Box>
                <Box className={styles.mainInfoLeft}>
                  <Box>
                    <Button
                      variant="contained"
                      className={styles.setAvatarBtn}
                      onClick={handleUploadPhotoOpen}
                    >
                      {t("account.setAvatarBtn")}
                      <UploadIconBtn className={styles.cameraIcon} />
                    </Button>
                    <FormErrorMessage
                      isError={isProfilePictureEmpty && isSubmitted}
                      message={t("errors.profilePictureRequired")}
                    />
                  </Box>
                </Box>
              </Box>
              {user?.status === USER_ME_STATUS.PROFILE_COMPLETED ? (
                <ShareBtn shareLink={`${MainAppDomain}/${handle}`} />
              ) : null}
            </Box>
          </>
        )}

        <Box>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Typography variant="subtitle" className={styles.subtitle}>
              {t("account.generalTitle")}
            </Typography>
            <Box className={styles.infoBlock}>
              <FormControl className={styles.formRow}>
                <Box className={styles.formCol}>
                  <Box className={styles.label}>
                    {t("account.displayNameLabel")}
                    <i>*</i>
                  </Box>
                  <TextField
                    label={null}
                    error={!!nameError}
                    className={styles.textarea}
                    type="text"
                    autoComplete="off"
                    {...register("name")}
                    placeholder={t("account.displayNameLabel")}
                  />
                  <FormErrorMessage
                    isError={!!nameError}
                    message={nameError?.message}
                  />
                </Box>
                <Box className={styles.formCol}>
                  <Box className={styles.label}>
                    {t("account.userNameLabel")}
                    <i>*</i>
                  </Box>
                  <TextField
                    label={null}
                    error={!!handleError}
                    className={styles.textarea}
                    type="text"
                    autoComplete="off"
                    {...register("handle")}
                    inputProps={{
                      maxLength: 40,
                      className: styles.inputAdornment,
                    }}
                    InputProps={{
                      startAdornment: (
                        <Box className={styles.startAdornment}>
                          {MainAppDomain}/
                        </Box>
                      ),
                      endAdornment: (
                        <ShareBtn
                          isText={false}
                          icon={<CopyIcon />}
                          className={styles.shareInput}
                          shareLink={`${MainAppDomain}/${handle}`}
                        />
                      ),
                    }}
                  />
                  <FormErrorMessage
                    isError={!!handleError}
                    message={handleError?.message}
                  />
                </Box>
              </FormControl>

              <FormControl className={`${styles.selectWrap} ${styles.formRow}`}>
                <Box className={styles.formCol}>
                  <Box className={styles.label}>{t("account.category")}</Box>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={getValues("category") || "none"}
                        label={"Category"}
                        IconComponent={ArrowDown}
                        className={`${styles.select} ${
                          getValues("category") === "none"
                            ? styles.selectOpacity
                            : ""
                        }`}
                        {...register("category")}
                        MenuProps={{
                          classes: { paper: styles.dropdownMenu },
                        }}
                        notched={false}
                      >
                        <MenuItem
                          key={"none"}
                          disabled
                          value={"none"}
                          className={styles.selectPlaceholder}
                        >
                          {t("account.selectCategoryPlaceholder")}
                        </MenuItem>
                        {Object.entries(getCategories({ t })).map(
                          (category) => (
                            <MenuItem key={category[0]} value={category[0]}>
                              {category[1]}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                    name="category"
                    control={control}
                    defaultValue=""
                  />
                </Box>
              </FormControl>

              <FormControl>
                <Box className={styles.label}>{t("account.bioLabel")}</Box>
                <TextField
                  label={null}
                  error={!!aboutError}
                  className={styles.textarea}
                  multiline
                  rows={4}
                  type="text"
                  autoComplete="off"
                  {...register("about")}
                  placeholder={t("account.textareaPlaceholder")}
                />
                <FormErrorMessage
                  isError={!!aboutError}
                  message={aboutError?.message}
                />
              </FormControl>
            </Box>
            <SocialMediaLinks
              setInputFields={setInputFields}
              inputFields={inputFields}
            >
              <FormErrorMessage
                className={styles.socialLinksError}
                isError={!hasTwoLinks && isSubmitted}
                message={t("errors.twoSocialMediaLinksRequired")}
              />
            </SocialMediaLinks>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              disabled={loading}
              className={styles.submitBtn}
            >
              {t("account.saveBtn")}
            </LoadingButton>
          </form>
        </Box>
      </Box>

      <ModalUploadPhoto
        open={openUploadPhoto}
        handleClose={handleUploadPhotoClose}
        handleClick={handleUploadPhoto}
        profileImageUrl={user?.profileImageUrl}
        isCanDeletePhoto={false}
      />
    </Page>
  );
};
export default Profile;
