import { CognitoRefreshToken, CognitoUser } from "amazon-cognito-identity-js";
import { AUTH, LANGUAGES_LIST, PATHS } from "Constants";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAuthProcess, setLoader } from "Redux/appSlice";
import { fetchUserMe } from "Redux/usersSlice";
import {
  clearTokens,
  getAccessToken,
  getIdToken,
  getRefreshToken,
  setAuthUsername,
  setGoogleAccessToken,
  setGoogleRefreshToken,
} from "Services/Auth/auth.service";

import { updateUser } from "../Api/userApi";
import up from "../userPool";
import { getAdminUserPool } from "../userPoolAdmin";
import { isAuthenticated, logout, setAuth, setEmulateId } from "./auth.service";
let userPool = up;

const AuthService = () => {
  const dispatch = useDispatch();
  const firstUpdate = useRef(true);
  let location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = isAuthenticated();

  const handleSession = () => {
    const emulateId = new URLSearchParams(location.search).get("emulateId");
    const username = new URLSearchParams(location.search).get("username");
    const refreshToken = new URLSearchParams(location.search).get(
      "refreshToken"
    );
    const googleRefreshToken = new URLSearchParams(location.search).get(
      "googleRefreshToken"
    );
    const googleAccessToken = new URLSearchParams(location.search).get(
      "googleAccessToken"
    );
    if (googleAccessToken && googleRefreshToken) {
      setGoogleAccessToken(googleAccessToken);
      setGoogleRefreshToken(googleRefreshToken);
      dispatch(setAuthProcess(false));
      navigate(PATHS.PROFILE);
      return;
    }
    if (emulateId) {
      userPool = getAdminUserPool();
    }

    if (username && refreshToken) {
      dispatch(setLoader(true));
      logout();
      dispatch(setAuthProcess(true));
      setAuth({
        data: {
          refreshToken,
        },
        isCrossDomain: true,
        dispatch,
      });
      const userData = {
        Username: username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.refreshSession(
        new CognitoRefreshToken({ RefreshToken: refreshToken }),
        (err, session) => {
          setAuth({ data: session, dispatch });
          setAuthUsername(username);
          if (emulateId) {
            setEmulateId(emulateId);
          }
          dispatch(setLoader(false));
          dispatch(setAuthProcess(false));
          emulateId && navigate(PATHS.PROFILE);
        }
      );
    } else {
      dispatch(setAuthProcess(false));
      setAuth({
        data: {
          accessToken: getAccessToken(),
          idToken: getIdToken(),
          refreshToken: getRefreshToken(),
          ...(username && { username }),
        },
        dispatch,
        isCrossDomain: true,
      });
    }
  };

  const handleLang = () => {
    const lang = new URLSearchParams(location.search).get("lang");
    if (lang && Object.values(LANGUAGES_LIST).includes(lang)) {
      localStorage.setItem("language", lang);
    }
  };

  useEffect(() => {
    if (firstUpdate.current) {
      handleLang();
      handleSession();
    }
  }, []);

  useEffect(() => {
    const refreshToken = new URLSearchParams(location.search).get(
      "refreshToken"
    );
    if (isLoggedIn && firstUpdate.current && !refreshToken) {
      firstUpdate.current = false;
      dispatch(fetchUserMe()).then(() => {
        if (localStorage.getItem(AUTH.REFERRAL_CODE)) {
          updateUser({
            referralCode: localStorage.getItem(AUTH.REFERRAL_CODE),
          }).then(() => localStorage.removeItem(AUTH.REFERRAL_CODE));
        }
      });
    }
  }, [isLoggedIn]);

  return null;
};

export default AuthService;
