import React, { useEffect } from "react";
import { getUserZendeskJWT } from "Services/Api/mainAppApi";
import { setZendeskToken } from "Services/Auth/auth.service";

const zendeskKey = process.env.REACT_APP_ZENDESK_KEY;

function loadScript(src, position, id, onLoaded) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("id", id);
  script.src = src;
  script.addEventListener("load", onLoaded);
  position.appendChild(script);
}
const hideWidgetButton = () => {
  if (window.zE) {
    window.zE("messenger", "show");
    window.zE("messenger", "close");
    window.zE("messenger:set", "zIndex", -99999);
  }
};
export const showWidgetButton = () => {
  if (!window.zE) return;
  window.zE("messenger:set", "zIndex", 99999);
  window.zE("messenger", "open");
};
const loginUser = async (jwtToken) => {
  try {
    window.zE("messenger", "loginUser", function (callback) {
      callback(jwtToken);
    });
  } catch (e) {
    console.log("Cannot loogin into Zendesk", e);
  }
};
const ZendeskWidget = ({ onLoaded }) => {
  const loaded = React.useRef(false);

  useEffect(() => {
    if (!loaded.current) {
      if (!document.querySelector("#ze-snippet")) {
        onLoaded(false);
        const initZendesk = () => {
          hideWidgetButton();
          window.zE("messenger:set", "cookies", true);
          getUserZendeskJWT()
            .then((data) => {
              setZendeskToken(data?.token);
              loginUser(data?.token);
              onLoaded(true);
              hideWidgetButton();
            })
            .catch((err) => {
              console.log("Cannot get Zendesk jwt", err);
            })
            .finally(() => {
              loaded.current = true;
            });
        };
        loadScript(
          `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`,
          document.head,
          "ze-snippet",
          initZendesk
        );
      }
    }
    return () => {
      loaded.current = false;
      var script = document.getElementById("ze-snippet");
      if (script != null && window.zE) {
        window.zE("messenger", "close");
        window.zE("messenger:set", "zIndex", -99999);
        window.zE("messenger:set", "cookies", false);
        document.head.removeChild(script);
        let el = document.getElementById("launcher");
        if (el != null) {
          el.remove();
        }
      }
    };
  }, []);

  return <></>;
};
export default React.memo(ZendeskWidget);
