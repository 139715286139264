import { format, isThisWeek, isToday } from "date-fns";
import DOMPurify from "dompurify";

import {
  AUDIO_FORMAT,
  CALL_STATUS,
  IMAGE_FORMAT,
  MainAppDomain,
  MEDIA_TYPES,
  REWARD_ORDER_STATUS,
  REWARDS_ACTION_TYPE,
  VIDEO_FORMAT,
} from "../Constants";
import { generateS3Url } from "../Services/Api/userApi";

const HEDERA_LINK_TESTNET =
  "https://testnet.hederaexplorer.io/search-details/token";
const HEDERA_LINK = "https://hederaexplorer.io/search-details/token";

export const pluralize = (val, word, plural = word + "s") => {
  const _pluralize = (num, word, plural = word + "s") =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === "object")
    return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
};
export const urlify = (text) => {
  const urlRegex = /https?:\/\/[^\s<>]+/g;
  return text.replace(urlRegex, function (url) {
    // Check if the URL is already inside an anchor tag
    if (/<a\b[^>]*>(.*?)<\/a>/i.test(url)) {
      return url;
    }
    return `<a href="${url}" onclick="event.stopPropagation()" rel="noopener noreferrer" target="_blank">${url}</a>`;
  });
};

export const truncate = (input, limit) => {
  if (input.length <= limit) {
    return input;
  }
  const truncatedText = input.substr(0, limit);
  const lastSpaceIndex = truncatedText.lastIndexOf(" ");
  if (lastSpaceIndex === -1) {
    return `${truncatedText}...`;
  }
  return `${truncatedText.substr(0, lastSpaceIndex)}...`;
};

export const formatPostMessage = (msg) =>
  msg
    .replace(/\t/g, "")
    .replace(/\n\n/g, "\n")
    .split("\n")
    .map((line, index) => (
      <p
        key={index}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(line, {
            ALLOWED_ATTR: ["target", "onclick", "href", "rel"],
          }),
        }}
      />
    ));

//converter array of objects to object with objects
/**
 * @param {string} key
 * @param {[object]} arr
 * */
export const arrayToObjConverter = ({ key, arr }) => {
  const obj = {};
  arr.forEach((item) => {
    obj[item[key]] = item;
  });
  return obj;
};

export const hederaLink = () => {
  if (process.env.REACT_APP_ENV === "production") {
    return HEDERA_LINK;
  }

  return HEDERA_LINK_TESTNET;
};

/**
 *
 * @param { Number } intervalMinutes
 * @param { Number|String|Date|null } date
 * @returns
 */
export const getTimeRange = ({ intervalMinutes, date }) => {
  const times = [];
  const now = date ? new Date(date) : new Date();
  const currentMinutesPastMidnight = now.getHours() * 60 + now.getMinutes();

  for (let i = 0; i < 24 * 60; i += intervalMinutes) {
    let hours = Math.floor(i / 60);
    const minutes = i % 60;
    const disabled = i < currentMinutesPastMidnight;

    const date = new Date(now);
    date.setHours(hours, minutes, 0, 0);
    const timestamp = date.getTime();

    const hoursStr = String(hours).padStart(2, "0");
    const minutesStr = String(minutes).padStart(2, "0");

    times.push({
      value: timestamp,
      label: `${hoursStr}:${minutesStr}`,
      disabled,
    });
  }

  return times;
};

export const getConvertedTimeZoneDate = (date, dateFormat) => {
  const lang = localStorage.getItem("language");

  return lang && !dateFormat
    ? new Date(date).toLocaleDateString(lang, {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : format(new Date(date), dateFormat || "dd MMM yyyy");
};

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Checks for iOS and Android devices
  return /android|iphone|ipad|ipod|windows phone/i.test(userAgent);
};

export const getMainAppInfluencerProfileLink = ({ id, handle }) => {
  if (!id && !handle) return null;
  return handle
    ? `${MainAppDomain}/${handle}`
    : `${MainAppDomain}/discover/influencer/${id}`;
};

export const getConvertedFiles = async ({ blobFiles }) => {
  const promises = blobFiles.map(async (file) => {
    const s3Response = await generateS3Url({
      type: file.type.split("/")[0],
      ContentType: file.type,
      extension: file.type.split("/")[1],
    });
    await fetch(s3Response.data.presignedUploadUrl, {
      method: "PUT",
      body: file,
    });

    return s3Response.data.presignedUrl;
  });

  const result = await Promise.all(promises);
  return result;
};

export const getFileTypeByUrl = (url) => {
  const parts = url.split(".");
  const extension = parts[parts.length - 1].toUpperCase();
  let type;
  if (IMAGE_FORMAT[extension]) {
    type = MEDIA_TYPES.IMAGE;
  } else if (VIDEO_FORMAT[extension]) {
    type = MEDIA_TYPES.VIDEO;
  } else if (AUDIO_FORMAT[extension]) {
    type = MEDIA_TYPES.AUDIO;
  } else {
    type = MEDIA_TYPES.FILE;
  }
  return type;
};

export const getRewardType = ({ value }) => {
  const types = {
    [REWARDS_ACTION_TYPE.PREMIUM_CHAT]: "limitedEdition.premiumChat",
    [REWARDS_ACTION_TYPE.FOLLOW_BACK]: "limitedEdition.followBack",
    [REWARDS_ACTION_TYPE.VIDEO_CALL]: "limitedEdition.videoCall",
  };

  return types[value];
};

export const getRewardStatus = ({ t, value }) => {
  const types = {
    [REWARD_ORDER_STATUS.OPEN]: t("limitedEdition.rewardStatusOpen"),
    [REWARD_ORDER_STATUS.PENDING_REPLY]: t(
      "limitedEdition.rewardStatusPendingReply"
    ),
    [REWARD_ORDER_STATUS.AWAITING]: t("limitedEdition.rewardStatusAwaiting"),
    [REWARD_ORDER_STATUS.DELIVERED]: t("limitedEdition.rewardStatusDelivered"),
    [REWARD_ORDER_STATUS.FAILED]: t("limitedEdition.rewardStatusFailed"),
    [CALL_STATUS.CANCELLED]: t("limitedEdition.callStatusCancelled"),
  };

  return types[value];
};

export const LOCAL_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const rewardFormatDate = (date, t) => {
  if (isToday(date)) {
    return `${t("default.todayAt")} ${format(date, "HH:mm")}`;
  } else if (isThisWeek(date)) {
    return `${format(date, "eeee, HH:mm")}`;
  } else {
    return `${format(date, "MMM d, HH:mm")}`;
  }
};
