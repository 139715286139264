import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as COMMUNITY_API from "Services/Api/communityApi";

import { arrayToObjConverter } from "../Utils";

export const fetchScheduledPosts = createAsyncThunk(
  "scheduled/posts/get",
  async (params) => await COMMUNITY_API.getPosts(params)
);

export const createScheduledPost = createAsyncThunk(
  "scheduled/posts/create",
  async (params, { rejectWithValue }) => {
    try {
      return await COMMUNITY_API.createPost(params);
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const updatePostScheduled = createAsyncThunk(
  "scheduled/post/update",
  async (params, { rejectWithValue }) => {
    try {
      return await COMMUNITY_API.updatePost(params);
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const deleteScheduledPost = createAsyncThunk(
  "scheduled/post/delete",
  async (params) => await COMMUNITY_API.deletePost(params)
);

const initialState = {
  posts: {},
  total: 0,
};

const appSlice = createSlice({
  name: "scheduledPosts",
  initialState,
  reducers: {
    resetScheduledPosts: (state) => {
      state.posts = {};
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScheduledPosts.fulfilled, (state, action) => {
      const isAdd = action?.meta?.arg?.isAdd;
      const data = arrayToObjConverter({
        key: "id",
        arr: action.payload?.posts,
      });
      if (isAdd) {
        state.posts = { ...state.posts, ...data };
      } else {
        state.posts = data;
      }
      state.total = action.payload.total;
    });
    builder.addCase(updatePostScheduled.fulfilled, (state, action) => {
      if (state?.posts) {
        const data = action?.meta?.arg || {};
        if (data.postId) {
          const dataMedia = data.files.reduce(
            (prev, acc) => {
              if (acc?.imageUrl) {
                prev.images.push(acc.imageUrl);
              }
              if (acc?.videoUrl) {
                prev.videos.push(acc.videoUrl);
              }
              return prev;
            },
            {
              images: [],
              videos: [],
            }
          );
          state.posts[data.postId] = {
            ...state.posts[data.postId],
            ...data,
            poll: {
              ...state.posts[data.postId]?.poll,
              options: data.options,
              question: data.question,
              duration: data.duration,
            },
            images: dataMedia.images,
            videos: dataMedia.videos,
            data: {
              ...(data?.data ? data.data : {}),
              youtubeUrl: data.youtubeUrl,
            },
          };
        }
      }
    });
    builder.addCase(deleteScheduledPost.fulfilled, (state, action) => {
      const { postId } = action?.meta?.arg;
      delete state.posts[postId];
    });
  },
});

export const { resetScheduledPosts } = appSlice.actions;
export default appSlice.reducer;
