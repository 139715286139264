import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ZendeskWidget, { showWidgetButton } from "Components/ZendeskWidget";
import {
  CARD_STATUS,
  LANGUAGES_LIST,
  PATHS,
  WEBSITE_LANG_STRINGS,
  ZOOP_POLICY_HOST,
} from "Constants/index";
import { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDataToConstructor } from "Redux/limitedEditionSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import styles from "./StatusBlock.module.scss";

const callSupport = (e) => {
  e.preventDefault();
  showWidgetButton();
};

export const StatusBlock = ({ isShow, card }) => {
  const { reviewStatus, id } = card;

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, localization } = useContext(LocalizationContext);

  const statusTitle = {
    [CARD_STATUS.NEW]: t("default.cardStatusInReview"),
    [CARD_STATUS.REJECTED]: t("default.cardStatusFailedReview"),
  };

  const statusColor =
    reviewStatus === CARD_STATUS.NEW
      ? theme.palette.dark.main
      : theme.palette.secondary.main;

  const bgColor =
    reviewStatus === CARD_STATUS.NEW
      ? theme.palette.lime.main
      : theme.palette.error.main;

  const getDynamicUrl = useCallback(
    (page) => {
      const langFormatted =
        WEBSITE_LANG_STRINGS[localization] ||
        WEBSITE_LANG_STRINGS[LANGUAGES_LIST.english];

      return `${ZOOP_POLICY_HOST}/${langFormatted}/${page}`;
    },
    [localization]
  );

  const goToEdit = () => {
    dispatch(setDataToConstructor(card));
    const path = PATHS.CARD_CONSTRUCTOR_EDIT.replace(":id", id);
    navigate(path);
  };
  const [ZeLoaded, setZeLoaded] = useState(false);

  if (!isShow) {
    return null;
  }

  return (
    <Box className={styles.status}>
      <Box className={styles.statusTitle}>
        <Typography variant="body1" className={styles.statusTitleLabel}>
          {t("default.status")}
        </Typography>
        <Typography
          variant="body3_medium"
          className={styles.statusTitleValue}
          style={{
            color: statusColor,
            background: bgColor,
          }}
        >
          {statusTitle[reviewStatus] || reviewStatus}
        </Typography>
      </Box>
      {reviewStatus === CARD_STATUS.NEW ? (
        <Typography variant="body1" className={styles.statusDescriptionTitle}>
          {t("limitedEdition.reviewCardStatusDescription")}
        </Typography>
      ) : null}
      {reviewStatus === CARD_STATUS.REJECTED ? (
        <>
          <Typography variant="body1">
            {t("limitedEdition.rejectedCardStatusDescription")}
          </Typography>
          <Typography variant="body1" className={styles.bottomTxt}>
            {t("limitedEdition.rejectStatusTextBeforeTerms")}{" "}
            <a
              href={getDynamicUrl("terms-of-service")}
              target="_blank"
              rel="noreferrer"
            >
              {t("default.zoopTermsAndConditions")}
            </a>{" "}
            {t("limitedEdition.rejectStatusTextAfterTerms")}{" "}
            <LoadingButton
              onClick={callSupport}
              loading={!ZeLoaded}
              variant="text"
              className={styles.btnLink}
            >
              <Typography variant="body1">
                {t("default.supportTeam")}
              </Typography>
            </LoadingButton>
            {". "}
            {t("default.rejectStatusTextAfterSupport")}
          </Typography>
        </>
      ) : null}
      <Button variant="contained" onClick={goToEdit}>
        {t("limitedEdition.editCard")}
      </Button>
      <ZendeskWidget onLoaded={(val) => setZeLoaded(val)} />
    </Box>
  );
};
