import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import * as COMMUNITY_API from "Services/Api/communityApi";
import {
  deleteComment,
  deleteCommentLike,
  getComments,
  getReactions,
  postCommentLike,
} from "Services/Api/communityApi";

import { arrayToObjConverter } from "../Utils";

export const createPost = createAsyncThunk(
  "community/posts/create",
  async (params, { rejectWithValue }) => {
    try {
      return await COMMUNITY_API.createPost(params);
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const deletePost = createAsyncThunk(
  "community/post/delete",
  async (params) => await COMMUNITY_API.deletePost(params)
);

export const updatePost = createAsyncThunk(
  "community/post/update",
  async (params, { rejectWithValue }) => {
    try {
      return await COMMUNITY_API.updatePost(params);
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const patchPost = createAsyncThunk(
  "community/post/patch",
  async (params) => await COMMUNITY_API.patchPost(params)
);

export const fetchPostById = createAsyncThunk(
  "community/post",
  async (params) => await COMMUNITY_API.getPostById(params)
);

export const fetchPostComments = createAsyncThunk(
  "community/posts/comments",
  async (params) => await COMMUNITY_API.getComments(params)
);

export const fetchPosts = createAsyncThunk(
  "community/posts/get",
  async (params) => await COMMUNITY_API.getPosts(params)
);

export const approveAllPosts = createAsyncThunk(
  "community/posts/approve",
  async (params) => await COMMUNITY_API.postApproveAll(params)
);

export const rejectAllPosts = createAsyncThunk(
  "community/posts/reject",
  async (params) => await COMMUNITY_API.postRejectAll(params)
);

export const fetchFans = createAsyncThunk(
  "community/fans/get",
  async (params) => await COMMUNITY_API.getFans(params)
);

export const allowPostingFan = createAsyncThunk(
  "community/fans/allow-posting",
  async (params) => await COMMUNITY_API.patchAllowPostingFan(params)
);

export const createMessage = createAsyncThunk(
  "community/post/message",
  async (params) => await COMMUNITY_API.sendMessage(params)
);

export const pinPost = createAsyncThunk(
  "community/post/pin",
  async (params) => await COMMUNITY_API.pinPost(params)
);

export const fetchReactions = createAsyncThunk(
  "community/post/reactions",
  async () => await getReactions()
);

export const getStripeAccount = createAsyncThunk(
  "stripe/account",
  async () => await COMMUNITY_API.stripeAccountLink()
);

export const fetchCommentReplies = createAsyncThunk(
  "community/post/comments-replies",
  async (params) => await getComments(params)
);

export const likeComment = createAsyncThunk(
  "community/post/comment/like",
  async (params) => await postCommentLike(params)
);
export const unlikeComment = createAsyncThunk(
  "community/post/comment/unlike",
  async (params) => await deleteCommentLike(params)
);

export const deleteComments = createAsyncThunk(
  "community/comments/delete",
  async (params) => {
    const response = await deleteComment(params);
    return response;
  }
);

const initialState = {
  posts: {
    entities: {
      pinnedPost: {},
      posts: [],
      total: 0,
    },
    comments: {
      entities: [],
      total: 0,
      loading: false,
      hasMore: true,
    },
    postById: null,
    loading: false,
  },
  postsInReview: {
    posts: {},
    hasMore: false,
    total: 0,
  },
  fans: {
    entities: {
      fans: [],
      total: 0,
    },
    loading: false,
  },
  reactions: {
    entities: [],
    loading: false,
  },
};

const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    changePostCommentsCount: (state, action) => {
      const { commentsCount } = action.payload;
      state.posts.comments.total = commentsCount;
    },
    resetComments: (state) => {
      state.posts.comments = initialState.posts.comments;
    },
    setPost: (state, action) => {
      state.posts.postById = action.payload;
    },
    setCommentIsLiked: (state, action) => {
      const { commentId, isLiked } = action.payload;
      let found = state.posts.comments.entities.find(
        (item) => item.id === commentId
      );

      if (!found) {
        state.posts.comments.entities.some((comment) => {
          if (comment.replies) {
            found = comment.replies.find((reply) => reply.id === commentId);
            return Boolean(found);
          }
          return false;
        });
      }

      if (found) {
        found.isLiked = isLiked;
        const likesCount = found?.likes || 0;
        found.likes = isLiked ? likesCount + 1 : likesCount - 1;
      }
    },
    updateRepliesCount: (state, action) => {
      state.posts.comments.entities = state.posts.comments.entities.map(
        (comment) => {
          const { replyToId, value } = action.payload;
          const hasReplyId = comment.replies?.find((r) => r.id === replyToId);

          if (comment.id === replyToId || hasReplyId) {
            if (value || value === 0) {
              comment.countReplies = value;
            } else {
              comment.countReplies = (comment.countReplies || 0) + 1;
            }
          }
          return comment;
        }
      );
    },
    setFansPage: (state, action) => {
      state.fans.entities.page = action.payload;
    },
    updateAllFans: (state, action) => {
      const data = action.payload || {};
      state.fans.entities.fans = state.fans.entities.fans.map((item) => ({
        ...item,
        ...data,
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state, action) => {
        if (action.meta.arg?.type !== "pending_review") {
          state.posts.loading = true;
        }
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        if (action.meta.arg?.type === "pending_review") {
          if (action.meta.arg?.page === 1) {
            state.postsInReview.posts = arrayToObjConverter({
              key: "id",
              arr: action.payload.posts || [],
            });
          } else {
            state.postsInReview.posts = {
              ...state.postsInReview.posts,
              ...arrayToObjConverter({
                key: "id",
                arr: action.payload.posts || [],
              }),
            };
          }
          state.postsInReview.total = action.payload.total;
          state.postsInReview.hasMore =
            action.payload.posts?.length === action.meta.arg?.limit;
        } else {
          state.posts.loading = false;
          if (action.meta.arg?.page === 1) {
            state.posts.entities.posts = action.payload.posts;
          } else {
            state.posts.entities.posts = [
              ...state.posts.entities.posts,
              ...(action.payload.posts || []),
            ];
          }
          state.posts.entities.total = action.payload.total;
          state.posts.entities.pinnedPost = action.payload.pinnedPost;
        }
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        if (action.meta.arg?.type !== "pending_review") {
          state.posts.loading = false;
        }
      })
      .addCase(approveAllPosts.fulfilled, (state) => {
        state.postsInReview.posts = [];
        state.postsInReview.total = 0;
        state.postsInReview.hasMore = false;
      })
      .addCase(rejectAllPosts.fulfilled, (state) => {
        state.postsInReview.posts = [];
        state.postsInReview.total = 0;
        state.postsInReview.hasMore = false;
      })
      .addCase(patchPost.fulfilled, (state, action) => {
        const { status } = action.meta.arg;
        const post = state.postsInReview.posts[action.meta.arg?.postId];
        if (status === "published" || status === "rejected") {
          state.postsInReview.total = state.postsInReview.total - 1;
          delete state.postsInReview.posts[action.meta.arg?.postId];
        }
        if (status === "published") {
          state.posts.entities.total = state.posts.entities.total + 1;
          state.posts.entities.posts.unshift(post);
        }
      })
      .addCase(fetchFans.pending, (state) => {
        state.fans.loading = true;
      })
      .addCase(fetchFans.fulfilled, (state, action) => {
        const { page = 1 } = action.meta.arg;
        state.fans.loading = false;
        if (page === 1) {
          state.fans.entities = action.payload || {};
        } else {
          state.fans.entities.fans = state.fans.entities.fans.concat(
            action.payload?.fans || []
          );
        }

        state.fans.entities.page = page;
      })
      .addCase(fetchFans.rejected, (state) => {
        state.fans.loading = false;
      })
      .addCase(allowPostingFan.fulfilled, (state, action) => {
        const { index, allowPosting } = action.meta.arg;
        if (state.fans.entities.fans[index]) {
          state.fans.entities.fans[index].allowPosting = allowPosting;
        }
      })
      .addCase(fetchPostById.pending, (state) => {
        state.posts.loading = true;
      })
      .addCase(fetchPostById.fulfilled, (state, action) => {
        state.posts.loading = false;
        state.posts.postById = action.payload;
      })
      .addCase(fetchPostById.rejected, (state) => {
        state.posts.loading = false;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        const { postId } = action.meta.arg;
        if (state.posts.entities.pinnedPost?.id === postId) {
          state.posts.entities.pinnedPost = {};
        }
        state.posts.entities.posts = state.posts.entities.posts.filter(
          (post) => post.id !== postId
        );
        state.posts.entities.total = state.posts.entities.total - 1;
      })
      .addCase(fetchPostComments.pending, (state) => {
        state.posts.comments.loading = true;
      })
      .addCase(fetchPostComments.fulfilled, (state, action) => {
        const { page, limit } = action.meta.arg.params;
        state.posts.comments.loading = false;
        const newData = action.payload.comments || [];
        if (page > 1) {
          state.posts.comments.entities =
            state.posts.comments.entities.concat(newData);
        } else {
          state.posts.comments.entities = newData;
        }
        state.posts.comments.hasMore = action.payload.total > page * limit;
        state.posts.comments.total = action.payload.total;
      })
      .addCase(fetchPostComments.rejected, (state) => {
        state.posts.comments.loading = false;
      })
      .addCase(createMessage.fulfilled, (state, action) => {
        if (!action?.meta?.arg.replyToId) {
          const { payload } = action;
          if (!payload.countReplies) payload.countReplies = 0;
          state.posts.comments.entities.unshift(payload);
          state.posts.comments.total = state.posts.comments.total + 1;
        } else if (action?.meta?.arg.replyToId) {
          state.posts.comments.total = state.posts.comments.total + 1;
        }
      })
      .addCase(fetchReactions.pending, (state) => {
        state.reactions.loading = true;
      })
      .addCase(fetchReactions.fulfilled, (state, action) => {
        state.reactions.loading = false;
        state.reactions.entities = action.payload;
      })
      .addCase(fetchReactions.rejected, (state) => {
        state.reactions.loading = false;
      })
      .addCase(fetchCommentReplies.fulfilled, (state, action) => {
        const { commentId } = action.meta.arg.params;
        state.posts.comments.entities = state.posts.comments.entities.map(
          (item) => {
            if (item.id === commentId) {
              item.replies = _.uniqBy(
                [...(item.replies || []), ...action.payload?.comments],
                "id"
              );
            } else if (
              item.replies &&
              item.replies.some((reply) => reply.id === commentId)
            ) {
              item.replies = _.uniqBy(
                [...(item.replies || []), ...action.payload?.comments],
                "id"
              );
            }
            return item;
          }
        );
      })
      .addCase(deleteComments.fulfilled, (state, action) => {
        const { commentId } = action.meta.arg;
        const index = state.posts.comments.entities.findIndex(
          (comment) => comment.id === commentId
        );
        if (index > -1) {
          state.posts.comments.entities.splice(index, 1);
        } else {
          for (let i = 0; i < state.posts.comments.entities.length; i++) {
            const comment = state.posts.comments.entities[i];
            const replyIndex = comment.replies?.findIndex(
              (reply) => reply.id === commentId
            );
            if (replyIndex > -1) {
              comment.replies.splice(replyIndex, 1);
              break;
            }
          }
        }
      });
  },
});

export const {
  changePostCommentsCount,
  resetComments,
  setPost,
  setCommentIsLiked,
  updateRepliesCount,
  setFansPage,
  updateAllFans,
} = communitySlice.actions;

export default communitySlice.reducer;
