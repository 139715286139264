import { ERROR_RESPONSE_MSG, ERROR_RESPONSE_TYPES } from "Constants/index";

const printMessage = (e: String) => {
  return e.replace("_", " ");
};
export const errorResponseMessages = (e: any, t: any) => {
  if (e.code) {
    switch (e.code) {
      case ERROR_RESPONSE_TYPES.LimitExceededException:
        if (e.message === ERROR_RESPONSE_MSG.LimitExceededTryLater) {
          return t("auth.attemptLimitExceededTryLater");
        }
        return t("auth.smsLimitExceeded");
      case ERROR_RESPONSE_TYPES.UsernameExistsException:
        return t("auth.userExists");
      case ERROR_RESPONSE_TYPES.AliasExistsException:
        return t("auth.userExists");
      case ERROR_RESPONSE_TYPES.CodeMismatchException:
      case ERROR_RESPONSE_TYPES.CodeExpiredException:
        if (e.message === ERROR_RESPONSE_TYPES.InvalidVerificationCode) {
          return t("auth.invalidVerificationCode");
        }
        return t("auth.verificationCodeInvalid");
      case ERROR_RESPONSE_TYPES.UserNotFoundException:
      case ERROR_RESPONSE_TYPES.InvalidPasswordException:
      case ERROR_RESPONSE_TYPES.NotAuthorizedException:
        if (e.message === ERROR_RESPONSE_MSG.PasswordAttemptsExceeded) {
          return t("auth.passwordAttemptsExceeded");
        }
        if (e.message === ERROR_RESPONSE_MSG.IncorrectUsernameOrPassword) {
          return t("errors.incorrectPassword");
        }
        return t("auth.invalidCredentials");
      case ERROR_RESPONSE_TYPES.SessionExpiredException:
        return t("auth.sessionExpired");
      case ERROR_RESPONSE_TYPES.SessionUnavailableOfflineException:
        return t("auth.offline");
      case ERROR_RESPONSE_TYPES.SignedOutException:
        return t("auth.signedOut");
      case ERROR_RESPONSE_TYPES.UserNotConfirmedException:
        return t("auth.unconfirmed");
      default:
        return e.message || t("auth.unknownError");
    }
  }
  const errorCode = e?.response?.data?.errorDetails?.code;
  if (errorCode) {
    switch (errorCode) {
      default:
        return (
          e?.response?.data?.errorDetails?.message || t("errors.unknownError")
        );
    }
  }

  if (e?.error) {
    switch (e.error) {
      case ERROR_RESPONSE_TYPES.AgeError:
        return t("errors.ageError");
      default:
        return e.message || t("errors.unknownError");
    }
  }

  if (e?.response?.data?.validationErrors) {
    const errors = e.response?.data?.validationErrors;
    const values = Object.values(errors);
    const res = [];
    values.forEach((item) => {
      Array.isArray(item) &&
        item.forEach((element) => {
          res.push(element);
        });
    });
    return printMessage(res[0]);
  } else {
    return printMessage(e.message || t("auth.unknownError"));
  }
};
