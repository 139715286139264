export const IconRedirect = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.82546 2.85608H2.67967C2.00557 2.85608 1.5 3.42104 1.5 4.04249V10.3135C1.5 10.9915 2.06175 11.4999 2.67967 11.4999H8.97125C9.64535 11.4999 10.1509 10.935 10.1509 10.3135V7.14975"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.49072 7.54528L11.5014 1.50024"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.4977 5.56793V1.50024H7.50928"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
