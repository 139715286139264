import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ChatWidgetModal } from "Components/Modal/components/ChatWidgetModal/ChatWidgetModal";
import { PATHS, REWARDS_ACTION_TYPE, TUTORIALS_PROPS } from "Constants/index";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedUser } from "Redux/messengerSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";

import { TutorialTooltip } from "../../../../../../Components/TutorialTooltip/TutorialTooltip";
import { getSeenTutorials } from "../../../../../../Redux/selectors/userSelector";
import { changeUser, updateUser } from "../../../../../../Redux/usersSlice";
import { getRewardType } from "../../../../../../Utils";
import { TimeSection } from "./components/TimeSection";
import styles from "./Order.module.scss";

export const Order = ({
  reward,
  appUser,
  index,
  cardId,
  id,
  status,
  call,
  date,
  ...rest
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isS = useMediaQuery("(max-width:920px)");

  const [isOpenChatWidget, setOpenChatWidget] = useState(false);
  const seenTutorials = useSelector(getSeenTutorials);

  const goToMessenger = () => {
    dispatch(setSelectedUser(appUser));
    navigate({
      pathname: PATHS.CHAT_DIRECT.replace(":userId", appUser.id),
    });
  };

  const clickHandler = () => {
    if (reward === REWARDS_ACTION_TYPE.PREMIUM_CHAT) {
      goToMessenger();
    }
    if (
      reward === REWARDS_ACTION_TYPE.FOLLOW_BACK ||
      reward === REWARDS_ACTION_TYPE.VIDEO_CALL
    ) {
      setOpenChatWidget(true);
    }
  };

  const closeTutorialTooltip = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [TUTORIALS_PROPS.FIRST_ORDER]: true,
      },
    };
    dispatch(changeUser(params));
    dispatch(updateUser(params));
  };

  return (
    <>
      <TutorialTooltip
        isOpen={!seenTutorials?.[TUTORIALS_PROPS.FIRST_ORDER] && !index}
        setOpen={closeTutorialTooltip}
        placement={isS ? "top" : "left"}
        title={t("default.firstOrderTooltipTitle")}
        subTitle={t("default.firstOrderTooltipSubTitle")}
      >
        <Box className={styles.order} onClick={clickHandler}>
          <Box className={styles.orderContent}>
            <img src={appUser?.profileImageUrl} alt="avatar" />
            <Box className={styles.orderContentRight}>
              <Typography variant="body1" className={styles.orderTitle}>
                {t(getRewardType({ value: reward }))}
              </Typography>
              <Typography
                variant="body3"
                className={`${styles.orderName} opacity_07`}
              >
                {appUser?.username}
              </Typography>
            </Box>
          </Box>
          <TimeSection callStart={call?.date} date={date} />
        </Box>
      </TutorialTooltip>
      <ChatWidgetModal
        isOpen={isOpenChatWidget}
        setOpen={setOpenChatWidget}
        appUser={appUser}
        orderId={id}
        cardId={cardId}
        reward={reward}
        index={index}
        status={status}
        duration={rest.duration || 15} // 15 for old cards
        isAbleToEdit={true}
      />
    </>
  );
};
