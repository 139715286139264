import { Box, TextField, Typography } from "@mui/material";
import { CustomAlert } from "Components/CustomAlert/CustomAlert";
import { CustomTooltip } from "Components/CustomTooltip/CustomTooltip";
import { ScheduledReward } from "Components/ScheduledReward/ScheduledReward";
import { REWARDS_ACTION_TYPE } from "Constants/index";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSeenTutorials } from "Redux/selectors/userSelector";
import { changeUser, updateUser } from "Redux/usersSlice";
import { LocalizationContext } from "Services/Localization/LocalizationContext";
import { useIsMobile } from "Utils/Hooks";

import { QuestionMarkIcon } from "../../icons";
import styles from "./RewardChat.module.scss";

export const RewardChat = ({
  isActive,
  setMessage,
  setRewardMessage,
  message,
  customAlertTitle,
  rewardType,
  tutorialName,
  disabled,
  duration,
  setDuration,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const seenTutorials = useSelector(getSeenTutorials);

  const [isOpen, setOpen] = useState(false);

  const closeAlertHandler = () => {
    const params = {
      seenTutorials: {
        ...seenTutorials,
        [tutorialName]: true,
      },
    };
    dispatch(changeUser(params));
    dispatch(updateUser(params));
  };
  const changeHandler = (e) => {
    if (
      rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL ||
      rewardType === REWARDS_ACTION_TYPE.FOLLOW_BACK
    ) {
      setRewardMessage((prevState) => ({
        ...prevState,
        [rewardType]: e.target.value,
      }));
    } else {
      setMessage(e.target.value);
    }
  };

  if (!isActive) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      <CustomAlert
        title={customAlertTitle}
        severity="info"
        onClose={closeAlertHandler}
        isShow={!seenTutorials?.[tutorialName]}
      />
      <ScheduledReward
        isShow={rewardType === REWARDS_ACTION_TYPE.VIDEO_CALL}
        rowClassName={styles.scheduledRewardRow}
        disabled={disabled}
        duration={duration}
        setDuration={setDuration}
      />
      <Box className={styles.field}>
        <Box
          className={styles.label}
          onClick={() => setTimeout(() => setOpen(true), 0)}
        >
          <Typography variant="body3_medium_14_display">
            {t("limitedEdition.message")}
          </Typography>
          <CustomTooltip
            isArrow={true}
            title={t("limitedEdition.premiumChatTooltip")}
            tooltipClassName={styles.tooltip}
            isClick={isMobile}
            setOpen={setOpen}
            open={isOpen}
          >
            <Box>
              <QuestionMarkIcon />
            </Box>
          </CustomTooltip>
        </Box>
        <TextField
          variant="outlined"
          disabled={disabled}
          placeholder={t("limitedEdition.premiumChatPlaceholder")}
          multiline={true}
          className={styles.textarea}
          inputProps={{ maxLength: 4000 }}
          autoFocus={!message}
          onChange={changeHandler}
          value={message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </Box>
  );
};
